import React from "react";
import { useHistory } from "react-router";
import "./cardListFe.scss";

const CardListFE = ({ isTeacher, data }) => {
  const history = useHistory();
  let content = [];

  if (isTeacher) {
    content = data && data?.map((val, i) => {
      return (
        <div className='d-flex align-items-center justify-content-between card-list-fe' key={`tch${i}`}>
          <div className='d-flex align-items-center content-card'>
            <img
              src={val.image}
              alt=''
            />
            <div className='info'>
              <p className='nama'>{val.name}</p>
              <p className='kelas'>{val.subject}</p>
            </div>
          </div>
    
          <div className='button' style={{width:"27%"}}>
            <div className='button-detail' onClick={() => history.push(`/teacher/${val.id}`)}>
              View Details
            </div>
          </div>
        </div>
      );
    });
  } else {
    content = data && data?.map((val, i) => {
      return (
        <div className='d-flex align-items-center justify-content-between card-list-fe' key={`alm${i}`}>
          <div className='d-flex align-items-center content-card'>
            <img
              src={val.image}
              alt='nama'
            />
            <div className='info'>
              <p className='nama'>{val.name}</p>
              <p className='kelas'>{val.class}</p>
            </div>
          </div>
    
          <div className='button' style={{width:"27%"}}>
            <div className='button-detail' onClick={() => history.push(`/alumni/${val.id}`)}>
              View Details
            </div>
          </div>
        </div>
      );
    });
  }

  return content;
};

export default CardListFE;
