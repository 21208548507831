import React, { useEffect } from "react";
import "./yearbook.scss";
import { Helmet } from "react-helmet";
import { FaPencilAlt, FaGraduationCap } from "react-icons/fa";
import { MdContactPage } from "react-icons/md";
import { FiFileText } from "react-icons/fi";
// import { RiDeleteBin5Line } from "react-icons/ri";
import { BsDot } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getAllYearbooks, updateStatusYearBook } from "config/redux/action";
import { Loading, Pagination } from "components";

const Yearbook = () => {
  const { yearbook } = useSelector((state) => state.yearbookReducer);
  const { paginate } = useSelector((state) => state.mainReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllYearbooks(1, paginate.size));
  }, []);

  const toPage = (page) => {
    dispatch(getAllYearbooks(page, paginate.size));
  };

  if (!yearbook) {
    return (
      <div className='yearbook-list'>
        <div className='wrapper-button'>
          <h1>Yearbook List</h1>
          <div
            className='button-add'
            onClick={() => history.push("/admin/yearbook/add")}>
            + Add New Yearbook
          </div>
        </div>
        <h2>Tidak ada Data..</h2>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Admin Panel | Yearbook List</title>
        <link rel='canonical' href='https://kawankelasku.com/admin/yearbook' />
        <meta name='description' content='placeholder' />
      </Helmet>
      {yearbook.length === 0 ? (
        <Loading />
      ) : (
        <div className='yearbook-list'>
          <div className='wrapper-button'>
            <h1>Yearbook List</h1>
            <div
              className='button-add'
              onClick={() => history.push("/admin/yearbook/add")}>
              + Add New Yearbook
            </div>
          </div>

          {yearbook?.map((val, i) => (
            <div className='content-card' key={`data-${i}`}>
              <div className='card-name'>
                <p className='name'>{val.name}</p>
                <p className='code'>
                  sch{val.id} <BsDot /> <span className='year'>{val.year}</span>
                </p>
              </div>
              <div className='card-school'>
                <div className='icon'>
                  <FaGraduationCap size='35' />
                </div>
                <p className='card-item'>{val.schoolName}</p>
              </div>
              <div className='card-level'>
                <div className='icon'>
                  <MdContactPage size='30' />
                </div>
                <p className='card-item'>{val.levelName}</p>
              </div>
              <div className='card-status'>
                <div className='icon'>
                  <FiFileText size='30' />
                </div>
                <div>{val.status ? "Active" : "NonActive"}</div>
              </div>
              <div className='card-action'>
                {/* <div
                className='button-delete'
                onClick={() =>
                  dispatch(updateStatusYearBook(val.id))
                }
              >
                <RiDeleteBin5Line size='25' />
              </div> */}
                <div className='button-edit'>
                  <FaPencilAlt
                    size='25'
                    onClick={() =>
                      history.push(`/admin/yearbook/edit/${val.id}`)
                    }
                  />
                </div>
              </div>
            </div>
          ))}

          {paginate?.count !== 0 && (
            <Pagination
              onClick={toPage}
              page={paginate.count / paginate.size}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Yearbook;
