const initialState = {
  userSchoolDetails: null,
  schoolDetails: null,
  schoolLevelDetails: [],
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_USER_SCHOOL":
      return {
        ...state,
        userSchoolDetails: action.payload
      }
    case "ADD_SCHOOL_DETAIL":
      return {
        ...state,
        schoolDetails: action.payload,
      };
    case "ADD_SCHOOL_LEVEL_DETAIL":
      return {
        ...state,
        schoolLevelDetails: action.payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
