import React, { useEffect } from "react";
import { CoomingSoon } from "assets";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

const Homepage = () => {
  const state = useSelector((state) => state.loginReducer);
  const history = useHistory();

  useEffect(() => {
    history.push("/login");
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Yearbook Apps</title>
        <link rel='canonical' href='/' />
        <meta name='description' content='placeholder' />
      </Helmet>
      <div className='d-flex justify-content-center'>
        <img src={CoomingSoon} alt='cooming soon!' />
      </div>
    </>
  );
};

export default Homepage;
