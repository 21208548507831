const initialState = {
  schools: [],
  schoolLevels: [],
  detailSchool: null,
  isSubmitting: false,
  paginate: {
    page: 1,
    size: 5,
    count: 0,
  },
}

const moduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_SCHOOLS':
      return {
        ...state,
        schools: action.payload,
      }
    case 'ADD_SCHOOL_LEVELS':
      return {
        ...state,
        schoolLevels: action.payload,
      }
    case 'GET_DETAIL_SCHOOL':
      return {
        ...state,
        detailSchool: action.payload,
      }
    case 'ADD_PAGINATE':
      return {
        ...state,
        paginate: {
          ...state.paginate,
          page: action.payload.page,
          size: action.payload.size,
          count: action.payload.count,
        },
      }
    case 'SET_ISSUBMITTING':
      return {
        ...state,
        isSubmitting: action.payload,
      }
    default:
      return state
  }
}

export default moduleReducer
