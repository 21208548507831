const initialState = {
    teacher: [],
    detailTeacher: null,
  };
  
  const TeacherReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_TEACHERS":
        return {
          ...state,
          teacher: action.payload
        };
      case "GET_DETAIL_TEACHER":
        return {
          ...state,
          detailTeacher: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default TeacherReducer;