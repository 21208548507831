import React from "react";
import "./button.scss";

const Button = ({ label, isSubmitting }) => {
  return (
    <>
      {isSubmitting ? (
        <button className='submit-button loading' type='submit' disabled>
          <span
            className='spinner-border spinner-border-sm'
            role='status'
            aria-hidden='true'></span>
        </button>
      ) : (
        <button className='submit-button' type='submit'>
          {label}
        </button>
      )}
    </>
  );
};

export default Button;
