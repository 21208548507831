import React, { useEffect } from "react";
import "./detail-teacher.scss";
import { FaGraduationCap, FaFacebook } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { AiFillInstagram } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getTeacherDetail } from "config/redux/action";

const DetailTeacherFE = () => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.loginReducer);
  const { detailTeacher } = useSelector((state) => state.teacherReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeacherDetail(user.YearbookId, id))
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Yearbook Apps | Teacher Detail</title>
        <link rel='canonical' href='https://kawankelasku.com/teacher' />
        <meta name='description' content='placeholder' />
      </Helmet>
      <div className='detail-teacher'>
        <div className='wrapper-content'>
          <img src={detailTeacher?.image} alt='' width='100' height='100' />
          <div className='info'>
            <div className='details'>
              <h2>{detailTeacher?.name}</h2>
              <div className='detail-info'>
                <div className='icon'>
                  <FaGraduationCap size='27' />
                </div>
                <p>{detailTeacher?.subject}</p>
              </div>
              <div className='detail-info'>
                <div className='icon'>
                  <MdEmail size='25' />
                </div>
                <p>{detailTeacher?.email}</p>
              </div>
              <div className='detail-info'>
                <div className='icon'>
                  <AiFillInstagram size='26' />
                </div>
                <p>{detailTeacher?.instagram ? detailTeacher.instagram : '-'}</p>
              </div>
              <div className='detail-info'>
                <div className='icon'>
                  <FaFacebook size='25' />
                </div>
                <p>{detailTeacher?.facebook ? detailTeacher.facebook : '-'}</p>
              </div>
            </div>

            <div className='motto'>
              <p>
                “{detailTeacher?.motto ? detailTeacher.motto : '-'}”
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailTeacherFE;
