import React from "react";
import { Route } from "react-router-dom";
import { LayoutAuth } from "components/layout";

export default function Guest({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <LayoutAuth>
            <Component {...props} />
          </LayoutAuth>
        );
      }}
    />
  );
}
