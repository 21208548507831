const initialState = {
    alumni: [],
    detailAlumni: null,
  };
  
  const AlumniReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_ALUMNIES":
        return {
          ...state,
          alumni: action.payload
        };
      case "GET_DETAIL_ALUMNI":
        return {
          ...state,
          detailAlumni: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default AlumniReducer;