import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { FaArrowLeft, FaArrowRight, FaExpand, FaShareAlt } from 'react-icons/fa'
import './yearbook-fe.scss'
import { useSelector } from 'react-redux'
// import { getYearbookById } from "config/redux/action";
import { Document, Page, pdfjs } from 'react-pdf'
import { ShareSocial } from 'react-share-social'
import { useHistory } from 'react-router-dom'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const Yearbook = () => {
  // const dispatch = useDispatch();
  const history = useHistory()
  const [shareSocial, setShareSocial] = useState(false)
  // const { id } = useParams();
  // const { user } = useSelector((state) => state.loginReducer);
  // const { yearbookById } = useSelector((state) => state.yearbookReducer);
  const { pdfYearbook } = useSelector((state) => state.utilsReducer)

  useEffect(() => {
    // dispatch(getYearbookById(user.YearbookId));
    document.addEventListener('contextmenu', (event) => event.preventDefault())
  }, [])

  //   PDF
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const style = {
    borderRadius: 3,
    border: 0,
    color: 'white',
    padding: '0 30px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    position: 'absolute',
    top: -180,
    right: 0,
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Yearbook Apps | Yearbook</title>
        <link rel="canonical" href="https://kawankelasku.com/yearbook" />
        <meta name="description" content="placeholder" />
      </Helmet>
      <div className="yearbook-FE">
        <div className="image-wrappers">
          {/* <div > */}
          <Document className='canva-mobile' style={{
            display:'block',
            userSelect:'none',
          width: '324px',
          height: '842px' }} file={pdfYearbook} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
          {/* </div> */}
         
          <div 
            className="left button-arrow"
            onClick={() =>
              setPageNumber(pageNumber > 1 ? pageNumber - 1 : pageNumber)
            }
          >
            <FaArrowLeft color="#0F75BC" />
          </div>
          <div 
            className="right button-arrow"
            onClick={() =>
              setPageNumber(pageNumber < numPages ? pageNumber + 1 : pageNumber)
            }
          >
            <FaArrowRight color="#0F75BC" />
          </div>
        </div>

        <div className="buttons-yearbook">
          <div className="d-flex justify-content-between">
            <div className="wrapper-page">
              <span>
                {pageNumber} / {numPages}
              </span>
            </div>

            {/* <div className='zoom-wrapper'>
              <div className='button-adjust minus'>
                <FaMinus
                  fontSize={12}
                  color='#0F75BC'
                  onClick={() => setScale(scale - 0.1)}
                />
              </div>
              <span>100%</span>
              <div className='button-adjust plus'>
                <FaPlus
                  fontSize={12}
                  color='#fff'
                  onClick={() => setScale(scale + 0.1)}
                />
              </div>
            </div> */}
            <div className="other-buttons">
              {/* <div className='others th-table'>
                <FaThLarge fontSize={20} color='#0F75BC' />
              </div> */}
              {/* <div
                className='others share'
                onClick={() => setShareSocial(!shareSocial)}>
                <FaShareAlt fontSize={20} color='#0F75BC' />
                {shareSocial && (
                  <ShareSocial
                    style={style}
                    url={pdfYearbook}
                    socialTypes={['facebook', 'twitter', 'linkedin']}
                  />
                )}
              </div> */}
              <div
                className="others expand"
                // onClick={() => window.open(pdfYearbook, "_blank")}>
                onClick={() => history.push('/yearbook/pdf')}
              >
                <FaExpand fontSize={20} color="#0F75BC" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Yearbook
