import { APIURL } from "./../../";
import Swal from "sweetalert2";
import axios from "axios";
import { config } from "config/api";

// LOGIN
export const loginUser = (data) => {
  return (dispatch) => {
    const body = {
      email: data.email,
      password: data.password,
      roleId: data.roleId,
    };
    dispatch({
      type: "SET_ISSUBMITTING",
      payload: true,
    });
    axios
      .post(`${APIURL}/login`, body, config)
      .then((res) => {
        if (res.data.code == 200) {
          dispatch({
            type: "LOGIN",
            payload: res.data.data,
          });
          localStorage.setItem("TOKEN", res.data.data.token);
          dispatch({
            type: "SET_ISSUBMITTING",
            payload: false,
          });
          if (res.data.data.RoleId === 1 && res.data.data.Status === false) {
            window.location = "/register-success";
          } else if (
            res.data.data.RoleId === 1 &&
            res.data.data.Status === true
          ) {
            Swal.fire(
              "Berhasil",
              `Selamat datang di Yearbook Apps!`,
              "success"
            )
            .then((res) => {
              window.location = "/welcome"
            })
          }

          if (res.data.data.RoleId === 2) {
            window.location = "/admin/user-approval";
          }
        } else {
          Swal.fire("Gagal", `${res.data.message}`, "error");
          dispatch({
            type: "SET_ISSUBMITTING",
            payload: false,
          });
        }
      })
      .catch((err) => {
        Swal.fire("Gagal", "Terjadi kesalahan", "error");
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
      });
  };
};

// REGISTER
export const registerUser = (data) => {
  return (dispatch) => {
    const body = {
      email: data.email,
      password: data.password,
      roleId: 1,
      schoolId: data.schoolId,
      levelId: data.levelId,
      status: true,
      year: data.year,
      instagram: data.instagram,
      WhatsappNumber: data.whatsapp_number,
      fullName: data.fullName,
    };

    dispatch({
      type: "SET_ISSUBMITTING",
      payload: true,
    });
    axios
      .post(`${APIURL}/registration`, body, config, config)
      .then((res) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        if (res.data.code == 200) {
          Swal.fire("Berhasil", `Pendaftaran berhasil`, "success")
          .then((res) => {
            window.location = "/register-success";
          })
        } else {
          Swal.fire("Gagal", `${res.data.message}`, "error");
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        Swal.fire("Gagal", "Terjadi kesalahan", "error");
      });
  };
};

export const logout = (type) => {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT",
    });
    if (type === 2) {
      window.location = "/administrator";
    } else {
      window.location = "/login";
    }
  };
};
