import React from "react";
import ReactPaginate from "react-paginate";
import "./pagination.scss";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const PaginationPage = ({ count, page, size, onClick, paginationFE }) => {
  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    onClick(selectedPage);
  };

  if (paginationFE) {
    return (
      <div className='pagination-component-fe'>
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={page}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    );
  }
  return (
    <div className='pagination-component'>
      <ReactPaginate
        previousLabel={<FaChevronLeft color='#fff' />}
        nextLabel={<FaChevronRight color='#fff' />}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={page}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
};

export default PaginationPage;
