import { APIURL } from "config";
import Swal from "sweetalert2";
import { http } from "config";
import { getSchoolDetails } from ".";

export const getAllYearbooks = (page, size, name = "") => {
  return (dispatch) => {
    http
      .get(`${APIURL}/yearbook?page=${page}&size=${size}&Name=${name}`)
      // .get(`${APIURL}/yearbook?page=${page}&size=${size}&Name=${name}`)
      .then((res) => {
        if (res.data.code == 200) {
          dispatch({ type: "GET_ALL_YEARBOOKS", payload: res.data.data });
          if (page !== 0) {
            dispatch({
              type: "ADD_PAGINATE",
              payload: {
                page: res.data.page,
                size: res.data.size,
                count: res.data.count,
              },
            });
          }
        } else {
          Swal.fire("Gagal", `${res.data.returnMessage}`, "error");
        }
      })
      .catch((err) => {
        dispatch({ type: "GET_ALL_YEARBOOKS", payload: null });
        Swal.fire("Gagal", "Tidak ada data", "error");
      });
  };
};

export const getYearbookDetail = (id, isGallery) => {
  return (dispatch) => {
    http
      .get(`${APIURL}/yearbook/${id}`)
      .then((res) => {
        if (res.data.code == 200) {
          dispatch({ type: "GET_DETAIL_YEARBOOK", payload: res.data.data });
          if (!isGallery) {
            dispatch(getSchoolDetails(res.data.data.schoolId));
          }
        } else {
          Swal.fire("Gagal", `${res.data.returnMessage}`, "error");
        }
      })
      .catch((err) => {
        Swal.fire("Gagal", "Terjadi kesalahan", "error");
      });
  };
};

export const getYearbookById = (id) => {
  return (dispatch) => {
    http
      .get(`${APIURL}/yearbook/${id}/gallery`)
      .then((res) => {
        if (res.data.code == 200) {
          dispatch({ type: "GET_YEARBOOK_BY_ID", payload: res.data.data });
        } else {
          Swal.fire("Gagal", `${res.data.returnMessage}`, "error");
        }
      })
      .catch((err) => {
        Swal.fire("Gagal", "Terjadi kesalahan", "error");
      });
  };
};

export const postDataYearbook = (data) => {
  return (dispatch) => {
    const body = {
      name: data.name,
      levelId: data.levelId,
      schoolId: data.schoolId,
      url: data.url,
      year: data.year,
      status: data.status,
    };

    dispatch({
      type: "SET_ISSUBMITTING",
      payload: true,
    });

    http
      .post(`${APIURL}/yearbook`, body)
      .then((res) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        Swal.fire("Berhasil", "Berhasil membuat data!", "success").then(
          () => (window.location = "/admin/yearbook")
        );
      })
      .catch((err) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        console.log("err", err);
        Swal.fire("Gagal", `${err.message}`, "error");
      });
  };
};

export const updateDataYearBook = (id, data) => {
  return (dispatch) => {
    const body = {
      name: data.name,
      levelId: data.levelId,
      schoolId: data.schoolId,
      url: data.url,
      year: data.year,
      status: data.status,
    };

    dispatch({
      type: "SET_ISSUBMITTING",
      payload: true,
    });

    http
      .put(`${APIURL}/yearbook/${id}`, body)
      .then((res) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        Swal.fire("Berhasil", "Berhasil mengubah data!", "success").then(
          () => (window.location = "/admin/yearbook")
        );
      })
      .catch((err) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        console.log("err", err);
        Swal.fire("Gagal", `${err.message}`, "error");
      });
  };
};

export const updateStatusYearBook = (id, data) => {
  return (dispatch) => {
    const body = {
      status: data.status,
    };

    http
      .put(`${APIURL}/yearbook/${id}`, body)
      .then((res) => {
        Swal.fire("Berhasil", "Berhasil mengubah data!", "success").then(
          () => (window.location = "/admin/yearbook")
        );
      })
      .catch((err) => {
        console.log("err", err);
        Swal.fire("Gagal", `${err.message}`, "error");
      });
  };
};

export const deleteYearbook = (id) => {
  return (dispatch) => {
    http
      .delete(`${APIURL}/yearbook/${id}`)
      .then((res) => {
        Swal.fire("Berhasil", `Berhasil menghapus data!`, "success").then(
          () => (window.location = "/admin/yearbook")
        );
      })
      .catch((err) => {
        console.log("err", err);
        Swal.fire("Gagal", `Gagal menghapus data!`, "error");
      });
  };
};

export const getYearbookListByLvlAndSchool = () => {
  return (dispatch) => {
    http
      .get(`${APIURL}/yearbook/homepage`)
      .then((res) => {
        if (res.data.code == 200) {
          dispatch({ type: "GET_YEARBOOK_LVL_SCHOOL", payload: res.data.data });
        } else {
          Swal.fire("Gagal", `${res.data.returnMessage}`, "error");
        }
      })
      .catch((err) => {
        Swal.fire("Gagal", "Terjadi kesalahan", "error");
      });
  };
};
