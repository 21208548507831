import React, { useRef, useState } from 'react'
import './upload.scss'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { FaFileCsv, FaFileExcel, FaPlus, FaTrash } from 'react-icons/fa'
import { postFile, postTeacherByCsv } from 'config/redux/action'
import * as yup from 'yup'

const UploadTeacher = () => {
  const { yearbookid } = useParams()
  const [fileData, setFileData] = useState()
  const { isSubmitting } = useSelector((state) => state.mainReducer)
  const inputRef = useRef()
  const dispatch = useDispatch()

  const onBtnClick = () => {
    inputRef.current.click()
  }

  const testUpload = async (item, index) => {
    dispatch({
      type: 'SET_ISSUBMITTING',
      payload: true,
    })
    const data = await postFile(item)
    formik.setFieldValue(`file`, data?.filename)
    formik.setFieldValue(`filename`, data?.originalname)
    setFileData(data.filename)
    if (data) {
      dispatch({
        type: 'SET_ISSUBMITTING',
        payload: false,
      })
    }
  }

  const formik = useFormik({
    initialValues: {
      file: '',
      originalname: '',
    },
    validationSchema: yup.object({
      file: yup.string().required('Wajib memasukkan file'),
    }),

    onSubmit: (values) => {
      dispatch(postTeacherByCsv(yearbookid, fileData))
    },
  })

  const onHandleDelete = () => {
    formik.setFieldValue(`file`, '')
    formik.setFieldValue(`filename`, '')
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin Panel | Teacher Upload</title>
        <link rel="canonical" href="https://kawankelasku.com/admin/teacher" />
        <meta name="description" content="placeholder" />
      </Helmet>

      <div className="teacher-upload">
        <h2>Teacher - Upload Excel</h2>

        <div className="content-teacher">
          <form onSubmit={formik.handleSubmit}>
            <div className="details">
              <label>Excel File</label>
              <div>
                {formik.values.file ? (
                  <div className="input-image">
                    <div
                      className="trash-icon"
                      onClick={() => onHandleDelete()}
                    >
                      <FaTrash color="#fff" fontSize={14} />
                    </div>
                    <label className="preview-content">
                      <FaFileCsv color="red" /> {formik.values.filename}
                    </label>
                  </div>
                ) : (
                  <div className="input-image no-file" onClick={onBtnClick}>
                    <FaPlus />
                  </div>
                )}
                <input
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  ref={inputRef}
                  className="d-none"
                  onBlur={formik.handleBlur}
                  onChange={(e) => testUpload(e.target.files[0])}
                />
              </div>

              <div className="button-content">
                <div className="buttons row pb-4">
                  <div className="col-4">
                    <a
                      className="btn btn-block w-100 button-cancel"
                      onClick={() =>
                        (window.location = `/admin/teacher/${yearbookid}/list`)
                      }
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-8">
                    {isSubmitting ? (
                      <button
                        className="submit-button loading mt-0 w-100"
                        type="submit"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-block btn-primary w-100 button-save"
                        type="submit"
                      >
                        Upload
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default UploadTeacher
