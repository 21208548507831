import React, { useState } from 'react'
// import { useHistory } from 'react-router'
import './login-admin.scss'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { FormInput, Button } from 'components/atoms'
import { loginUser } from 'config/redux/action'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { AuthTitle2 } from 'assets'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

const LoginAdmin = () => {
  // const history = useHistory()
  const dispatch = useDispatch()
  // const { user } = useSelector((state) => state.loginReducer)
  const { isSubmitting } = useSelector((state) => state.mainReducer)
  const [isShow, setIsShow] = useState(true)

  useEffect(() => {
    // user && history.goBack()
    dispatch({
      type: "LOGOUT",
    });
  }, [])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      roleId: 2,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Alamat email tidak valid')
        .required('Wajib menambahkan alamat email'),
      password: yup
        .string()
        .min(6, 'Minimal 6 karakter')
        .required('Wajib menambahkan password'),
    }),

    onSubmit: (values) => {
      dispatch(loginUser(values))
    },
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login Admin | Yearbook Administrator</title>
        <link rel="canonical" href="https://kawankelasku.com/administrator" />
        <meta name="description" content="placeholder" />
      </Helmet>
      <div className="bg-layout">
        <div className="wrapper-card">
          <form onSubmit={formik.handleSubmit}>
            <div className="text-center">
              <img className="content-title" src={AuthTitle2} alt="" />
            </div>
            <div className="card-content">
              <div className="login-admin">
                <h2 className="title">Sign In</h2>
                <div className="input-group">
                  <FormInput
                    label="Email"
                    placeholder="Input your email"
                    errorMessage={formik.errors.email}
                    error={formik.errors.email && formik.touched.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    name="email"
                  />
                  <div className="input-field">
                    <label>Password</label>
                    <div className="groupping-input">
                      <input
                        className="input-item w-100"
                        type={isShow ? 'password' : 'text'}
                        placeholder="Input password"
                        name="password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                      <i onClick={() => setIsShow(!isShow)}>
                        {isShow ? <AiFillEye /> : <AiFillEyeInvisible />}
                      </i>
                    </div>
                    {formik.errors.password && formik.touched.password && (
                      <div className="text-danger input-invalid">
                        {formik.errors.password}
                      </div>
                    )}
                  </div>
                  <Button label="Sign In" isSubmitting={isSubmitting} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default LoginAdmin
