import React from "react";
import { Link } from "react-router-dom";
import "./menu.scss";

const MenuAdmin = ({ icon, label, active, link }) => {
  return (
    <Link
      to={`/admin${link}`}
      className={`menu-item-sidebar ${active && "active"}`}>
      {icon}
      <p>{label}</p>
    </Link>
  );
};

export default MenuAdmin;
