import React from "react";
import "./form-input.scss";

const Input = ({
  label,
  placeholder,
  type,
  onBlur,
  onChange,
  errorMessage,
  error,
  name,
  value,
  disabled,
  className,
  noLabel,
  accept,
}) => {
  if (noLabel) {
    return (
      <div className={`input-field ${className}`}>
        <input
          className='input-item'
          type={type}
          placeholder={placeholder}
          onBlur={onBlur}
          name={name}
          onChange={onChange}
          value={value}
          disabled={disabled}
          accept={accept}
        />
        {error && (
          <div className='text-danger input-invalid'>{errorMessage}</div>
        )}
      </div>
    );
  }
  return (
    <div className={`input-field ${className}`}>
      <label>{label}</label>
      <input
        className='input-item'
        type={type}
        placeholder={placeholder}
        onBlur={onBlur}
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
        accept={accept}
      />
      {error && <div className='text-danger input-invalid'>{errorMessage}</div>}
    </div>
  );
};

export default Input;
