import React, { useEffect } from "react";
import "./school.scss";
import { Helmet } from "react-helmet";
import { FaMapMarkerAlt, FaPencilAlt } from "react-icons/fa";
import { FiFileText } from "react-icons/fi";
// import { RiDeleteBin5Line } from "react-icons/ri";
import {
  // deleteSchool,
  getAllSchools,
} from "config/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Loading, Pagination } from "components";

const School = () => {
  const { schools, paginate } = useSelector((state) => state.mainReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllSchools(1, paginate.size));
  }, []);

  // PAGINATION
  const toPage = (page) => {
    dispatch(getAllSchools(page, paginate.size));
  };

  if (!schools) {
    return (
      <div className='school-list'>
        <div className='wrapper-button'>
          <h1>School List</h1>
          <div
            className='button-add'
            onClick={() => history.push(`/admin/school/add`)}>
            + Add New School
          </div>
        </div>
        <h2>Tidak ada Data..</h2>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Admin Panel | School List</title>
        <link rel='canonical' href='https://kawankelasku.com/admin/school' />
        <meta name='description' content='placeholder' />
      </Helmet>

      {schools.length === 0 ? (
        <Loading />
      ) : (
        <div className='school-list'>
          <div className='wrapper-button'>
            <h1>School List</h1>
            <div
              className='button-add'
              onClick={() => history.push(`/admin/school/add`)}>
              + Add New School
            </div>
          </div>

          {schools.map((val, i) => (
            <div className='content-card' key={`data-${i}`}>
              <div className='card-name'>
                <p className='name'>{val.Name}</p>
                <p className='email'>sch0{val.Id}</p>
              </div>
              <div className='card-address'>
                <div className='icon'>
                  <FaMapMarkerAlt size='30' />
                </div>
                <p className='card-item'>{val.address ? val.address : "-"}</p>
              </div>
              <div className='card-status'>
                <div className='icon'>
                  <FiFileText size='30' />
                </div>
                <div>{val.status ? "Active" : "NonActive"}</div>
              </div>
              <div className='card-action'>
                {/* <div
                className='button-delete'
                onClick={() => dispatch(deleteSchool(val.id))}  
              >
                <RiDeleteBin5Line size='25' />
              </div> */}
                <div className='button-edit'>
                  <FaPencilAlt
                    size='25'
                    onClick={() => history.push(`/admin/school/edit/${val.id}`)}
                  />
                </div>
              </div>
            </div>
          ))}

          <Pagination onClick={toPage} page={paginate.count / paginate.size} />
        </div>
      )}
    </>
  );
};

export default School;
