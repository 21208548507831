import { APIURL } from "config";
import Swal from "sweetalert2";
import { http } from "config";

export const getAllUsers = (page, size, name = "") => {
  return (dispatch) => {
    http
      .get(`${APIURL}/user?page=${page}&size=${size}&status=false&Name=${name}`)
      .then((res) => {
        if (res.data.code == 200) {
          dispatch({ type: "GET_ALL_USERS", payload: res.data.data });
          dispatch({
            type: "ADD_PAGINATE",
            payload: {
              page: res.data.page,
              size: res.data.size,
              count: res.data.count,
            },
          });
        } else {
          Swal.fire("Gagal", `${res.data.returnMessage}`, "error");
        }
      })
      .catch((err) => {
        dispatch({ type: "GET_ALL_USERS", payload: null });
        Swal.fire("Gagal", "Tidak ada data", "error");
      });
  };
};

export const getAllActiveUsers = (page, size, name = "") => {
  return (dispatch) => {
    http
      .get(`${APIURL}/user?page=${page}&size=${size}&status=true&Name=${name}`)
      .then((res) => {
        if (res.data.code == 200) {
          dispatch({ type: "GET_ACTIVE_USERS", payload: res.data.data });
          dispatch({
            type: "ADD_PAGINATE",
            payload: {
              page: res.data.page,
              size: res.data.size,
              count: res.data.count,
            },
          });
        } else {
          Swal.fire("Gagal", `${res.data.returnMessage}`, "error");
        }
      })
      .catch((err) => {
        dispatch({ type: "GET_ACTIVE_USERS", payload: null });
        Swal.fire("Gagal", "Terjadi kesalahan", "error");
      });
  };
};

export const getUserDetail = (id) => {
  return (dispatch) => {
    http
      .get(`${APIURL}/user/${id}`)
      .then((res) => {
        if (res.data.code == 200) {
          dispatch({ type: "GET_DETAIL_USER", payload: res.data.data });
        } else {
          Swal.fire("Gagal", `${res.data.returnMessage}`, "error");
        }
      })
      .catch((err) => {
        Swal.fire("Gagal", "Terjadi kesalahan", "error");
      });
  };
};

export const updateDataUser = (id, data) => {
  return (dispatch) => {
    const parseStatus = (status) => {
      if (status === "true") {
        return true;
      } else if (status === "false") {
        return false;
      }
    };

    const body = {
      fullName: data.fullName,
      email: data.email,
      schoolId: data.schoolId,
      password: data.password,
      year: data.year,
      whatsappNumber: data.whatsappNumber,
      instagram: data.instagram,
      status: parseStatus(data.status),
    };

    dispatch({
      type: "SET_ISSUBMITTING",
      payload: true,
    });
    http
      .put(`${APIURL}/user/${id}`, body)
      .then((res) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        Swal.fire("Berhasil", `Berhasil mengubah data!`, "success").then(
          () => (window.location = "/admin/user-setting")
        );
      })
      .catch((err) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        console.log("err", err);
        Swal.fire("Gagal", `Gagal mengubah data!`, "error");
      });
  };
};

export const updateStatusUser = (id, data) => {
  return (dispatch) => {
    const body = {
      email: data.email,
      status: data.status,
    };

    http
      .put(`${APIURL}/user/${id}`, body)
      .then((res) => {
        Swal.fire("Berhasil", `Berhasil mengubah data!`, "success").then(
          () => (window.location = "/admin/user-setting")
        );
      })
      .catch((err) => {
        console.log("err", err);
        Swal.fire("Gagal", `Gagal mengubah data!`, "error");
      });
  };
};

export const approveRejectUser = (data, status) => {
  return (dispatch) => {
    const body = {
      email: data,
      notes: status ? "Approved" : "Rejected",
      status,
    };

    dispatch({
      type: "SET_ISSUBMITTING",
      payload: true,
    });
    http
      .put(`${APIURL}/user/approval`, body)
      .then((res) => {
        // Swal.fire("Berhasil", `Berhasil mengubah data!`, "success");
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        window.location = "/admin/user-approval";
      })
      .catch((err) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        console.log("err", err);
        Swal.fire("Gagal", `Gagal mengubah data!`, "error");
      });
  };
};

export const deleteUser = (id) => {
  return (dispatch) => {
    http
      .delete(`${APIURL}/user/${id}`)
      .then((res) => {
        Swal.fire("Berhasil", `Berhasil menghapus data!`, "success").then(
          () => (window.location = "/admin/user-setting")
        );
      })
      .catch((err) => {
        console.log("err", err);
        Swal.fire("Gagal", `Gagal menghapus data!`, "error");
      });
  };
};
