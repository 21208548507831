import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { CardListFE, Loading, Pagination } from "components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getAllTeachers } from "config/redux/action";
import { useState } from "react";

const Teacher = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const { id } = useParams();
  const { user } = useSelector((state) => state.loginReducer);
  const { teacher } = useSelector((state) => state.teacherReducer);
  const { paginate } = useSelector((state) => state.mainReducer);

  const [pageNumber, setPageNumber] = useState(0)

  

  const per_page = 5

  const offset = pageNumber * per_page

  const currentPage = teacher.slice(offset, offset + per_page)

  const pageCount = teacher.length === 5 || teacher.length < 5 ? Math.ceil((teacher.length / per_page)) : Math.ceil((teacher.length / per_page) -1)

  const handlepage = (pages) => {
    // console.log('sa',pages);
    setPageNumber(pages)
  }


  const toPage = (page) => {
    dispatch(
      getAllTeachers(user.YearbookId, page, paginate.size, { isFrontend: true })
    );
  };

  useEffect(() => {
    // console.log('paginate',paginate)
    dispatch(getAllTeachers(user.YearbookId, 1, 5, { isFrontend: true }));
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Yearbook Apps | Teacher</title>
        <link rel='canonical' href='https://kawankelasku.com/teacher' />
        <meta name='description' content='placeholder' />
      </Helmet>
      <div>
        <div className='cards'>
          {teacher?.length > 0 ? (
            <>
              {teacher !== null ? (
                <CardListFE isTeacher data={currentPage} />
              ) : (
                <h2>Tidak ada data!</h2>
              )}
            </>
          ) : (
            <Loading />
          )}
        </div>

        {/* {teacher?.length > 0 && (
          <Pagination
            onClick={toPage}
            page={paginate.count / paginate.size}
            paginationFE
          />
        )} */}

        {teacher?.length > 0 && (
          <Pagination
            onClick={handlepage}
            page={pageCount}
            paginationFE
          />
        )}
      </div>
    </>
  );
};

export default Teacher;
