const initialState = {
  pdfYearbook: null,
};

const UtilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_PDF_YEARBOOK":
      return {
        ...state,
        pdfYearbook: action.payload,
      }
    default:
      return state
  }
}

export default UtilsReducer;