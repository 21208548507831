import React from "react";
import "./form-select.scss";

const FormSelect = ({
  name,
  label,
  data,
  placeholder,
  onChange,
  onBlur,
  labelClass,
  value,
  errorMessage,
  error
}) => {
  return (
    <div className='form'>
      <label className={`${labelClass}`}>{label}</label>
      <select
        className='input-item'
        onChange={onChange}
        name={name}
        onBlur={onBlur}>
        <option hidden>{placeholder}</option>
        {data?.map((item, index) => (
          <option
            id={item.id}
            key={index}
            value={item.Name}
            selected={value === item.Name}>
            {item.Name ? item.Name : item.name}
          </option>
        ))}
      </select>
      {error && <div className='text-danger input-invalid'>{errorMessage}</div>}
    </div>
  );
};

export default FormSelect;
