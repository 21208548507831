import { APIURL } from "config";
import Swal from "sweetalert2";
import { http } from "config";

export const getSchoolLevelDetails = (id) => {
  return (dispatch) => {
    http
      .get(`${APIURL}/level/school/${id}`)
      .then((res) => {
        dispatch({
          type: "ADD_SCHOOL_LEVEL_DETAIL",
          payload: res.data.data.level,
        });
      })
      .catch((err) => {
        Swal.fire("Gagal", "Tidak ada data level", "error");
        dispatch({
          type: "ADD_SCHOOL_LEVEL_DETAIL",
          payload: [],
        });
      });
  };
};

export const getSchoolDetails = (id) => {
  return (dispatch) => {
    http
      .get(`${APIURL}/school/${id}?page=0&size=0`)
      .then((res) => {
        dispatch({ type: "ADD_SCHOOL_DETAIL", payload: res.data.data.levels });
        dispatch(getSchoolLevelDetails(res.data.data.id));
      })
      .catch((err) => {
        Swal.fire("Gagal", "Tidak ada data sekolah", "error");
      });
  };
};

export const getUserSchoolDetails = (id) => {
  return (dispatch) => {
    http
      .get(`${APIURL}/user/${id}?page=0&size=0`)
      .then((res) => {
        dispatch({ type: "ADD_USER_SCHOOL", payload: res.data.data });
        dispatch(getSchoolDetails(res.data.data.schoolId));
      })
      .catch((err) => {
        Swal.fire("Gagal", "Tidak ada data user", "error");
      });
  };
};
