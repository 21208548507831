import React, { useEffect, useState } from 'react'
import './layout-admin.scss'
import { OnlineYearbook } from 'assets'
import Menu from './menu'
import {
  FaUser,
  // FaHome,
  FaUserPlus,
  FaUsersCog,
  FaGraduationCap,
  FaBookOpen,
  FaUsers,
  FaUserGraduate,
  FaRegImages,
} from 'react-icons/fa'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { MdLogout } from 'react-icons/md'
import {
  getAllActiveUsers,
  getAllAlumnies,
  getAllGalleries,
  getAllSchools,
  getAllTeachers,
  getAllUsers,
  getAllYearbooks,
  logout,
} from 'config/redux/action'
import { useDispatch } from 'react-redux'

const Index = ({ children }) => {
  const { user } = useSelector((state) => state.loginReducer)
  const { isSubmitting } = useSelector((state) => state.mainReducer)
  const params = useLocation().pathname.split('/')[(1, 2)]
  const yearbookId = useLocation().pathname.split('/')[(2, 3)]
  const history = useHistory()
  const dispatch = useDispatch()
  const [dropdownActive, setDropdownActive] = useState(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    setSearch('')
    if (!user) {
      history.push('/administrator')
    } else if (user.RoleId !== 2) {
      history.push('/about-school')
    }
  }, [])

  const menus = [
    // {
    //   id: 1,
    //   name: "Home",
    //   link: "/admin",
    //   icon: <FaHome />
    // },
    {
      id: 2,
      name: 'User Approval',
      link: 'user-approval',
      icon: <FaUserPlus />,
    },
    {
      id: 3,
      name: 'User Setting',
      link: 'user-setting',
      icon: <FaUsersCog />,
    },
    {
      id: 4,
      name: 'School',
      link: 'school',
      icon: <FaGraduationCap />,
    },
    {
      id: 5,
      name: 'Yearbook',
      link: 'yearbook',
      icon: <FaBookOpen />,
    },
    {
      id: 6,
      name: 'Alumni',
      link: 'alumni',
      icon: <FaUsers />,
    },
    {
      id: 7,
      name: 'Teacher',
      link: 'teacher',
      icon: <FaUserGraduate />,
    },
    {
      id: 8,
      name: 'Gallery',
      link: 'gallery',
      icon: <FaRegImages />,
    },
  ]

  const onHandleSearch = () => {
    if (params === 'user-approval') {
      dispatch(getAllUsers(1, 99999999, search))
    } else if (params === 'user-setting') {
      dispatch(getAllActiveUsers(1, 99999999, search))
    } else if (params === 'school') {
      dispatch(getAllSchools(1, 99999999, search))
    } else if (params === 'yearbook') {
      dispatch(getAllYearbooks(1, 99999999, search))
    } else if (params === 'gallery') {
      dispatch(getAllYearbooks(1, 99999999, search))
    } else if (params === 'alumni') {
      dispatch(
        getAllAlumnies(yearbookId, 1, 99999999, { isFrontend: false }, search),
      )
    } else if (params === 'teacher') {
      dispatch(
        getAllTeachers(yearbookId, 1, 99999999, { isFrontend: false }, search),
      )
    }
  }

  return (
    <>
      {/* {isSubmitting ? (
        <div className='transparent'>
          <h2>Loading...</h2>
        </div>
      ) : ( */}
      <div className="layout-admin">
        <div className="sidebar">
          <div className="image-sidebar">
            <img src={OnlineYearbook} alt="logo" />
          </div>

          <div className="menu-group">
            {menus.map((menu, i) => (
              <Menu
                key={`menu-${i}`}
                icon={menu.icon}
                label={menu.name}
                active={params === menu.link}
                link={`/${menu.link}`}
              />
            ))}
          </div>
        </div>
        <div className="content">
          <div className="header-admin">
            <div className="search">
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search what you need here"
              />
              <div
                className="button-search btn btn-primary text-white"
                onClick={onHandleSearch}
              >
                Search
              </div>
            </div>

            <div className="wrapper-profile">
              <div className="profil">
                <p className="nama">{user?.FullName}</p>
                <div
                  className="icon"
                  onClick={() => setDropdownActive(!dropdownActive)}
                >
                  <FaUser />
                </div>
              </div>
              {dropdownActive && (
                <div
                  className="dropdown-profile"
                  onBlur={() => setDropdownActive(false)}
                >
                  {/* <div className='profile-btn mb-3 p-3'>
                  <FaUser />
                  <p>Profile</p>
                </div> */}
                  <div
                    className="logout-btn p-3"
                    onClick={() => dispatch(logout(2))}
                  >
                    <MdLogout />
                    <p>Logout</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {children}
        </div>
      </div>
      {/* )} */}
    </>
  )
}

export default Index
