import { APIURL } from "config";
import axios from "axios";
import Swal from "sweetalert2";
import { http } from "config";

export const getAllSchools = (page, size, name = "") => {
  return (dispatch) => {
    axios
      .get(`${APIURL}/school?page=${page}&size=${size}&Name=${name}`)
      .then((res) => {
        if (res.data.code == 200) {
          dispatch({
            type: "ADD_SCHOOLS",
            payload: page === 0 ? res.data.school : res.data.data.school,
          });
          if (page !== 0) {
            dispatch({
              type: "ADD_PAGINATE",
              payload: {
                page: res.data.page,
                size: res.data.size,
                count: res.data.count,
              },
            });
          } else if (page === 0 && name) {
            dispatch({
              type: "ADD_PAGINATE",
              payload: {
                page: 0,
                size: res.data.size,
                count: 0,
              },
            });
          }
        } else {
          dispatch({
            type: "ADD_SCHOOLS",
            payload: null,
          });
          Swal.fire("Gagal", `${res.data.ReturnMessage}`, "error");
        }
      })
      .catch((err) => {
        dispatch({
          type: "ADD_SCHOOLS",
          payload: null,
        });
        Swal.fire("Gagal", "Terjadi kesalahan", "error");
      });
  };
};

export const getSchoolById = (id) => {
  return (dispatch) => {
    http
      .get(`${APIURL}/school/${id}`)
      .then((res) => {
        if (res.data.code == 200) {
          dispatch({
            type: "GET_DETAIL_SCHOOL",
            payload: res.data.data,
          });
        } else {
          Swal.fire("Gagal", `${res.data.ReturnMessage}`, "error");
        }
      })
      .catch((err) => {
        Swal.fire("Gagal", "Terjadi kesalahan", "error");
      });
  };
};

export const getLevelByShoolId = (id) => {
  return (dispatch) => {
    http
      .get(`${APIURL}/level/school/${id}`)
      .then((res) => {
        if (res.data.code == 200) {
          dispatch({
            type: "ADD_SCHOOL_LEVELS",
            payload: res.data.data.level,
          });
        } else {
          Swal.fire("Gagal", `${res.data.ReturnMessage}`, "error");
        }
      })
      .catch((err) => {
        Swal.fire("Gagal", "Tidak ada data level", "error");
        dispatch({
          type: "ADD_SCHOOL_LEVELS",
          payload: [],
        });
      });
  };
};

export const postDataSchool = (data) => {
  return (dispatch) => {
    let newLevel = data.levels.map((lvl) => ({
      id: 0,
      name: lvl.name,
      status: lvl.status,
    }));
    let newContent = data.contents.map((content) => ({
      id: 0,
      title: content.title,
      status: content.status,
      description: content.description,
      url: content.url,
    }));
    const body = {
      Name: data.school_name,
      address: data.address,
      status: data.status,
      phoneNumber: data.phone_number,
      levels: newLevel.length > 0 ? newLevel : [],
      contents: newContent.length > 0 ? newContent : [],
    };

    dispatch({
      type: "SET_ISSUBMITTING",
      payload: true,
    });
    http
      .post(`${APIURL}/School`, body)
      .then((res) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        Swal.fire("Berhasil", `Berhasil menambah sekolah!`, "success").then(
          () => (window.location = "/admin/school")
        );
      })
      .catch((err) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        console.log("err", err);
        Swal.fire("Gagal", `${err.message}`, "error");
      });
  };
};

export const updateDataSchool = (id, data) => {
  return (dispatch) => {
    let newLevel = data.levels.map((lvl) => ({
      id: lvl.id !== 0 ? lvl.id : 0,
      name: lvl.name ? lvl.name : lvl.Name,
      status: lvl.Status,
    }));
    let newContent = data.contents.map((content) => ({
      id: content.id !== 0 ? content.id : 0,
      title: content.Title,
      status: content.Status,
      description: content.Description,
      url: content.Url,
    }));
    const body = {
      Name: data.name,
      address: data.address,
      status: data.status,
      phoneNumber: data.phoneNumber,
      levels: newLevel.length > 0 ? newLevel : [],
      contents: newContent.length > 0 ? newContent : [],
    };

    dispatch({
      type: "SET_ISSUBMITTING",
      payload: true,
    });
    http
      .put(`${APIURL}/school/${id}`, body)
      .then((res) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        Swal.fire("Berhasil", `Berhasil mengubah data!`, "success").then(
          () => (window.location = "/admin/school")
        );
      })
      .catch((err) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        console.log("err", err);
        Swal.fire("Gagal", `Gagal mengubah data!`, "error");
      });
  };
};

export const updateStatusSchool = (id, data) => {
  return (dispatch) => {
    const body = {
      name: data.name,
      status: data.status,
    };

    http
      .put(`${APIURL}/school/${id}`, body)
      .then((res) => {
        Swal.fire("Berhasil", `Berhasil mengubah data!`, "success").then(
          () => (window.location = "/admin/school")
        );
      })
      .catch((err) => {
        console.log("err", err);
        Swal.fire("Gagal", `Gagal mengubah data!`, "error");
      });
  };
};

export const postImage = async (image) => {
  let formData = new FormData();
  formData.append("image", image);
  formData.append("status", true);
  const res = await http.post(`${APIURL}/upload`, formData);
  if (res.data) {
    return res.data.data;
  } else {
    Swal.fire("Gagal", `${res.message}`, "error");
  }
};

export const postFile = async (file) => {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("status", true);
  const res = await http.post(`${APIURL}/upload/files`, formData);
  if (res.data) {
    return res.data.data;
  } else {
    Swal.fire("Gagal", `${res.message}`, "error");
  }
};

export const deleteSchool = (id) => {
  return (dispatch) => {
    http
      .delete(`${APIURL}/school/${id}`)
      .then((res) => {
        Swal.fire("Berhasil", `Berhasil menghapus data!`, "success").then(
          () => (window.location = "/admin/school")
        );
      })
      .catch((err) => {
        console.log("err", err);
        Swal.fire("Gagal", `Gagal menghapus data!`, "error");
      });
  };
};
