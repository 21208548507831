import React, { useState } from 'react'
import './register.scss'
import { useHistory } from 'react-router'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { FormInput, FormSelect, Button } from 'components/atoms'
import {
  getAllSchools,
  getLevelByShoolId,
  registerUser,
} from 'config/redux/action'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

const Register = () => {
  const [tabIndex, setTabIndex] = useState(1)
  const [isShow, setIsShow] = useState(true)
  const [isShowCrf, setIsShowCrf] = useState(true)
  const history = useHistory()
  const dispatch = useDispatch()
  const { schools, schoolLevels, isSubmitting } = useSelector(
    (state) => state.mainReducer,
  )
  const { user } = useSelector((state) => state.loginReducer)

  useEffect(() => {
    // user && history.goBack()
    dispatch({
      type: "LOGOUT",
    });
    dispatch(getAllSchools(0, 0))
  }, [])

  const newLevel = schoolLevels?.filter((lvl) => lvl.Status === true)

  const formik = useFormik({
    initialValues: {
      // TAB 1
      gender: 'male',
      fullName: '',
      email: '',
      password: '',
      confirm_password: '',

      // TAB 2
      schoolId: '',
      levelId: '',
      year: '',
      whatsapp_number: '',
      instagram: '',
    },
    validationSchema: yup.object({
      // VALIDASI TAB 1
      gender: yup.string().required('Wajib memilih jenis kelamin'),
      fullName: yup
        .string()
        .min(2, 'Harus diisi dengan nama lengkap')
        .required('Wajib menambahkan nama lengkap'),
      email: yup
        .string()
        .email('Alamat email tidak valid')
        .required('Wajib menambahkan alamat email'),
      password: yup
        .string()
        .min(6, 'Minimal 6 karakter')
        .required('Wajib menambahkan password'),
      confirm_password: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Password konfirmasi harus sesuai')
        .required('Masukkan kembali password anda!'),

      // VALIDASI TAB 2
      schoolId: yup.string().required('Wajib memilih sekolah'),
      levelId: yup.string().required('Wajib memilih level'),
      year: yup.string().required('Wajib memilih tahun'),
      whatsapp_number: yup
        .number()
        .typeError('Harus berupa angka')
        .required('Masukkan nomor whatsapp anda'),
      instagram: yup.string().notRequired(),
    }),

    onSubmit: (values) => {
      dispatch(registerUser(values))
    },
  })

  const handleTab = (type) => {
    if (type === 'next') {
      if (tabIndex >= 1 && tabIndex < 3) {
        let value = tabIndex + 1
        setTabIndex(value)
      } else if (tabIndex === 3) {
        return
      }
    } else {
      if (tabIndex <= 3 && tabIndex !== 1) {
        let value = tabIndex - 1
        setTabIndex(value)
      } else if (tabIndex === 1) {
        return
      }
    }
  }

  const buttonInvalid = () => {
    if (tabIndex === 1) {
      return (
        !formik.values.fullName ||
        !formik.values.gender ||
        !formik.values.email ||
        !formik.values.password ||
        !formik.values.confirm_password ||
        formik.errors.fullName ||
        formik.errors.gender ||
        formik.errors.email ||
        formik.errors.password ||
        formik.errors.confirm_password
      )
    } else if (tabIndex === 2) {
      return (
        formik.errors.schoolId || formik.errors.levelId || formik.errors.year
      )
    }
  }

  const viewOption = () => {
    if (tabIndex === 1) {
      return (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Yearbook Apps | Register</title>
            <link rel="canonical" href="https://kawankelasku.com/register" />
            <meta name="description" content="placeholder" />
          </Helmet>
          <div className="input-field">
            <label>Gender</label>
            <div className="group-item my-0">
              <div className="input-radio">
                <input
                  type="radio"
                  id="male"
                  checked={formik.values.gender === 'male'}
                  onChange={() => {
                    formik.setFieldValue('gender', 'male')
                  }}
                />
                <label className="my-0" htmlFor="male">
                  Male
                </label>
              </div>
              <div className="input-radio">
                <input
                  type="radio"
                  id="female"
                  checked={formik.values.gender === 'female'}
                  onChange={() => {
                    formik.setFieldValue('gender', 'female')
                  }}
                />
                <label className="my-0" htmlFor="female">
                  Female
                </label>
              </div>
            </div>
            {formik.errors.gender && (
              <div className="text-danger input-invalid">
                {formik.errors.gender}
              </div>
            )}
          </div>
          <FormInput
            label="Full Name"
            placeholder="Input your full name"
            errorMessage={formik.errors.fullName}
            error={formik.errors.fullName && formik.touched.fullName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            name="fullName"
          />
          <FormInput
            label="Email"
            placeholder="Input your email"
            errorMessage={formik.errors.email}
            error={formik.errors.email && formik.touched.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            name="email"
          />

          <div className="input-password">
            <label>Password</label>
            <div className="groupping-input">
              <input
                className="input-item w-100"
                type={isShow ? 'password' : 'text'}
                placeholder="Input password"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              <i onClick={() => setIsShow(!isShow)}>
                {isShow ? <AiFillEye /> : <AiFillEyeInvisible />}
              </i>
            </div>
            {formik.errors.password && formik.touched.password && (
              <div className="text-danger input-invalid">
                {formik.errors.password}
              </div>
            )}
          </div>

          <div className="input-password">
            <label>Confirm Password</label>
            <div className="groupping-input">
              <input
                className="input-item w-100"
                type={isShowCrf ? 'password' : 'text'}
                placeholder="Confirm your password"
                name="confirm_password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.confirm_password}
              />
              <i onClick={() => setIsShowCrf(!isShowCrf)}>
                {isShowCrf ? <AiFillEye /> : <AiFillEyeInvisible />}
              </i>
            </div>
            {formik.errors.confirm_password &&
              formik.touched.confirm_password && (
                <div className="text-danger input-invalid">
                  {formik.errors.confirm_password}
                </div>
              )}
          </div>
        </>
      )
    } else if (tabIndex === 2) {
      return (
        <>
          <FormSelect
            label="School"
            data={schools.filter(item => item.status)}
            placeholder="Choose your school"
            onChange={(e) => {
              formik.setFieldValue(
                'schoolId',
                e.target[e.target.selectedIndex].id,
              )
              dispatch(getLevelByShoolId(e.target[e.target.selectedIndex].id))
            }}
            value={formik.values.schoolId}
            errorMessage={formik.errors.schoolId}
            error={formik.errors.schoolId && formik.touched.schoolId}
            name="school"
            onBlur={formik.handleBlur}
          />
          <FormSelect
            label="Level"
            data={newLevel}
            placeholder="Choose your level"
            onChange={(e) =>
              formik.setFieldValue(
                'levelId',
                e.target[e.target.selectedIndex].id,
              )
            }
            value={formik.values.levelId}
            errorMessage={formik.errors.levelId}
            error={formik.errors.levelId && formik.touched.levelId}
            name="levelId"
            onBlur={formik.handleBlur}
          />
          <FormSelect
            label="Year"
            data={[
              { id: 1, Name: '2006' },
              { id: 2, Name: '2007' },
              { id: 3, Name: '2008' },
              { id: 4, Name: '2009' },
              { id: 5, Name: '2010' },
              { id: 6, Name: '2011' },
              { id: 7, Name: '2012' },
              { id: 8, Name: '2013' },
              { id: 9, Name: '2014' },
              { id: 10, Name: '2015' },
              { id: 11, Name: '2016' },
              { id: 12, Name: '2017' },
              { id: 13, Name: '2018' },
              { id: 14, Name: '2019' },
              { id: 15, Name: '2020' },
              { id: 16, Name: '2021' },
              { id: 17, Name: '2022' },
              { id: 18, Name: '2023' },
            ]}
            placeholder="Choose your year"
            value={formik.values.year}
            name="year"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={formik.errors.year}
            error={formik.errors.year && formik.touched.year}
          />
          <FormInput
            label="Whatsapp Number"
            placeholder="Input your whatsapp number"
            errorMessage={formik.errors.whatsapp_number}
            error={
              formik.errors.whatsapp_number && formik.touched.whatsapp_number
            }
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            name="whatsapp_number"
            type="number"
          />
          <FormInput
            label="Instagram"
            placeholder="Input your instagram link"
            errorMessage={formik.errors.instagram}
            error={formik.errors.instagram && formik.touched.instagram}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            name="instagram"
          />
        </>
      )
    }
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="card-content">
          <div className="content-register">
            <h2 className="title">
              {tabIndex !== 3 ? `Register (${tabIndex}/2)` : `Register Success`}
            </h2>

            <div className="input-group">
              {viewOption()}

              {tabIndex !== 4 && (
                <div className="button-group">
                  <button
                    className="cancel-button"
                    onClick={() => handleTab('prev')}
                  >
                    Cancel
                  </button>

                  {!buttonInvalid() ? (
                    <>
                      {tabIndex === 2 ? (
                        // <button className='submit-button' type='submit'>
                        //   Register
                        // </button>
                        <Button label="Register" isSubmitting={isSubmitting} />
                      ) : (
                        <button
                          className="submit-button"
                          onClick={() => handleTab('next')}
                          type="submit"
                        >
                          Next
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      {/* <button className='submit-button loading'>
                        {tabIndex < 3 ? "Next" : "Register"}
                      </button> */}
                      <Button
                        label={tabIndex < 3 ? 'Next' : 'Register'}
                        isSubmitting={isSubmitting}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card-item mt-2">
          Already have an account ?{' '}
          <a onClick={() => history.push('/login')}>Sign In</a>
        </div>
      </form>
    </>
  )
}

export default Register
