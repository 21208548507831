import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getYearbookGallery } from "config/redux/action";
import "./gallery.scss";
import { Helmet } from "react-helmet";
import Lightbox from "react-image-lightbox";
import { Loading } from "components";

const Gallery = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.loginReducer);

  const { images } = useSelector((state) => state.galleryReducer);

  const [lightbox, setLightbox] = useState({
    photo: "",
    isOpen: false,
  });

  useEffect(() => {
    dispatch(getYearbookGallery(user?.YearbookId));
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Yearbook Apps | Gallery</title>
        <link rel='canonical' href='https://kawankelasku.com/gallery' />
        <meta name='description' content='placeholder' />
      </Helmet>
      {images.length === 0 ? (
        <Loading />
      ) : (
        <div className='gallery-FE'>
          {images?.map((arrays, i) => (
            <div className='div' key={`gl-${i}`}>
              {i % 2 == 0 ? (
                <div className='mb-4'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <img
                        onClick={(e) => {
                          e.preventDefault();
                          setLightbox({
                            ...lightbox,
                            isOpen: true,
                            photo: arrays[0],
                          });
                        }}
                        src={arrays[0]?.url}
                        alt='1'
                      />
                    </div>
                    <div className='col-md-6'>
                      <div className='row'>
                        {arrays[1] && (
                          <div className=''>
                            <img
                              onClick={(e) => {
                                e.preventDefault();
                                setLightbox({
                                  ...lightbox,
                                  isOpen: true,
                                  photo: arrays[1],
                                });
                              }}
                              src={arrays[1]?.url}
                              className='mb-4'
                              alt='2'
                            />
                          </div>
                        )}
                        
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                  {arrays[2] && (
                          <div className='col-md-6'>
                            <img
                              onClick={(e) => {
                                e.preventDefault();
                                setLightbox({
                                  ...lightbox,
                                  isOpen: true,
                                  photo: arrays[2],
                                });
                              }}
                              src={arrays[2]?.url}
                              className='mb-4'
                              alt='3'
                            />
                          </div>
                        )}
                        {arrays[3] && (
                          <div className='col-md-6'>
                            <img
                              onClick={(e) => {
                                e.preventDefault();
                                setLightbox({
                                  ...lightbox,
                                  isOpen: true,
                                  photo: arrays[3],
                                });
                              }}
                              src={arrays[3]?.url}
                              alt='4'
                            />
                          </div>
                        )}
                        
                  </div>

                  <div className='row'>
                  {arrays[4] && (
                          <div className='col-md-6'>
                            <img
                              onClick={(e) => {
                                e.preventDefault();
                                setLightbox({
                                  ...lightbox,
                                  isOpen: true,
                                  photo: arrays[4],
                                });
                              }}
                              src={arrays[4]?.url}
                              alt='5'
                            />
                          </div>
                        )}
                  </div>
                </div>
              ) : (
                <div className='mb-4'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='row'>
                        {arrays[0] && (
                          <div className='col-6'>
                            <img
                              onClick={(e) => {
                                e.preventDefault();
                                setLightbox({
                                  ...lightbox,
                                  isOpen: true,
                                  photo: arrays[0],
                                });
                              }}
                              src={arrays[0]?.url}
                              className='mb-4'
                              alt='2'
                            />
                          </div>
                        )}
                        {arrays[1] && (
                          <div className='col-6'>
                            <img
                              onClick={(e) => {
                                e.preventDefault();
                                setLightbox({
                                  ...lightbox,
                                  isOpen: true,
                                  photo: arrays[1],
                                });
                              }}
                              src={arrays[1]?.url}
                              className='mb-4'
                              alt='3'
                            />
                          </div>
                        )}
                        {arrays[4] && (
                          <div className='col-6'>
                            <img
                              onClick={(e) => {
                                e.preventDefault();
                                setLightbox({
                                  ...lightbox,
                                  isOpen: true,
                                  photo: arrays[4],
                                });
                              }}
                              src={arrays[4]?.url}
                              alt='4'
                            />
                          </div>
                        )}
                        {arrays[3] && (
                          <div className='col-6'>
                            <img
                              onClick={(e) => {
                                e.preventDefault();
                                setLightbox({
                                  ...lightbox,
                                  isOpen: true,
                                  photo: arrays[3],
                                });
                              }}
                              src={arrays[3]?.url}
                              alt='5'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {arrays[2] && (
                      <div className='col-md-6'>
                        <img
                          onClick={(e) => {
                            e.preventDefault();
                            setLightbox({
                              ...lightbox,
                              isOpen: true,
                              photo: arrays[2],
                            });
                          }}
                          src={arrays[2]?.url}
                          alt='1'
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {lightbox.isOpen && (
        <Lightbox
          mainSrc={lightbox.photo.url}
          onCloseRequest={(e) => {
            e.preventDefault();
            setLightbox({
              ...lightbox,
              isOpen: false,
            });
          }}
        />
      )}
    </>
  );
};

export default Gallery;
