import React, { useEffect, useState } from 'react'
import './about-school.scss'
// import { BoxItem } from "assets";
import { FaArrowRight } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Carousel } from 'react-responsive-carousel'
import { useDispatch, useSelector } from 'react-redux'
import {
  getYearbookListByLvlAndSchool,
  getSchoolById,
  getPdfYearbook,
} from 'config/redux/action'
import Swal from 'sweetalert2'

const AboutSchool = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.loginReducer)
  const { detailSchool } = useSelector((state) => state.mainReducer)
  const { yearbookLvlSchool } = useSelector((state) => state.yearbookReducer)
  const [active, setActive] = useState(false)
  const [yearValue, setYearValue] = useState('')
  const [pdf, setPdf] = useState('')
  const color = ['color-one', 'color-two', 'color-three', 'color-four', 'color-one', 'color-two', 'color-three', 'color-four', 'color-one', 'color-two', 'color-three', 'color-four']

  useEffect(() => {
    dispatch(getSchoolById(user.schoolId))
    dispatch(getYearbookListByLvlAndSchool())
  }, [])

  const validationNextPage = () => {
    if (user?.Year === yearValue) {
      dispatch(getPdfYearbook(pdf))
      history.push('/yearbook')
    } else {
      Swal.fire('Gagal', 'Anda tidak terdaftar di tahun ini', 'error')
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Yearbook Apps | About School</title>
        <link rel="canonical" href="https://kawankelasku.com/about-school" />
        <meta name="description" content="placeholder" />
      </Helmet>
      <div className="about-school">
        <div className="content-carousel">
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            swipeable={true}
            showIndicators={true}
          >
            {detailSchool?.contents.map((item, i) => (
              <div className="wrapper-group" key={`cts-${i}`}>
                <img src={item.Url} />
                <div className="wrapper-desc">
                  <div className="title">{item.Title}</div>
                  <div className="description">{item.Description}</div>
                </div>
                <div className="background-wrapper"></div>
              </div>
            ))}
          </Carousel>
        </div>

        <div className="content-body">
          <div className="row item-content">
            {yearbookLvlSchool?.map((lvl, idx) => (
              <div
                className="col-md-6 mb-3 d-flex"
                key={`lv-${idx}`}
                onClick={() => setActive(lvl.levelName)}
              >
                {/* <img src={BoxItem} alt='' width='150' height='150' /> */}
                <div className={`box-card ${color[idx]}`}>
                  {active === lvl.levelName ? (
                    <div className="select-year">
                      <p className="year-label">Year</p>
                      <div className="select-form">
                        <select
                          onChange={(e) => {
                            setYearValue(e.target.value)
                            setPdf(e.target[e.target.selectedIndex].id)
                          }}
                        >
                          <option hidden>Select year</option>
                          {lvl.yearbook.slice().sort((a, b) => b.Year - a.Year)?.map((year, idz) => {
                          // {lvl.yearbook?.map((year, idz) => {
                            if (year.Status) {
                              return (
                                <option
                                  id={year.Url}
                                  value={year.Year}
                                  key={`yr-${idz}`}
                                >
                                  {year.Year}
                                </option>
                              )
                            }
                          })}
                        </select>

                        <div
                          className="arrow"
                          onClick={() => validationNextPage()}
                        >
                          <FaArrowRight color="#fff" fontWeight={700} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="box-item">
                      <div className="blue-line" />
                      <p>{lvl.levelName}</p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="content-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15863.824081267547!2d106.9667726!3d-6.2695145!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4db42b05fbba033d!2sUnity%20School!5e0!3m2!1sid!2sid!4v1638549949509!5m2!1sid!2sid"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </>
  )
}

export default AboutSchool
