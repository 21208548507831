import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { CardListFE, Loading, Pagination } from "components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getAllAlumnies } from "config/redux/action";

const Alumni = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const { id } = useParams();
  const { user } = useSelector((state) => state.loginReducer);
  const { alumni } = useSelector((state) => state.alumniReducer);
  const { paginate } = useSelector((state) => state.mainReducer);

  const toPage = (page) => {
    dispatch(
      getAllAlumnies(user.YearbookId, page, paginate.size, { isFrontend: true })
    );
  };

  useEffect(() => {
    dispatch(getAllAlumnies(user.YearbookId, 1, 5, { isFrontend: true }));
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Yearbook Apps | Alumni List</title>
        <link rel='canonical' href='https://kawankelasku.com/alumni' />
        <meta name='description' content='placeholder' />
      </Helmet>
      <div>
        <div className='cards'>
          {alumni?.length > 0 ? (
            <>
              {alumni !== null ? (
                <CardListFE data={alumni} />
              ) : (
                <h3>Tidak ada data!</h3>
              )}
            </>
          ) : (
            <Loading />
          )}
        </div>

        {alumni?.length > 0 && (
          <Pagination
            onClick={toPage}
            page={paginate.count / paginate.size}
            paginationFE
          />
        )}
      </div>
    </>
  );
};

export default Alumni;
