import React, { useState, useEffect } from 'react'
import './edit-user-setting.scss'
import { useFormik } from 'formik'
import { FormInput, FormSelect } from 'components'
import { FaGraduationCap } from 'react-icons/fa'
import { FiFileText } from 'react-icons/fi'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  deleteUser,
  getAllSchools,
  getLevelByShoolId,
  getUserDetail,
  getUserSchoolDetails,
  updateDataUser,
} from 'config/redux/action'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { Helmet } from 'react-helmet'
import * as yup from 'yup'

const EditUserSetting = () => {
  const { id } = useParams()
  const { detailUser } = useSelector((state) => state.userReducer)
  const { schools, schoolLevels } = useSelector((state) => state.mainReducer)
  const { schoolLevelDetails } = useSelector((state) => state.adminReducer)
  const { isSubmitting } = useSelector((state) => state.mainReducer)
  const [dataBySchool, setDataBySchool] = useState(false)
  const [isShow, setIsShow] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserDetail(id))
    dispatch(getAllSchools(0, 0))
    dispatch(getUserSchoolDetails(id))
  }, [])

  const newLevel = schoolLevels?.filter((lvl) => lvl.Status === true)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...detailUser,
    },
    validationSchema: yup.object({
      fullName: yup
        .string()
        .min(2, 'Harus diisi dengan nama lengkap')
        .required('Wajib menambahkan nama lengkap'),
      email: yup
        .string()
        .email('Alamat email tidak valid')
        .required('Wajib menambahkan alamat email'),
      schoolName: yup.string().required('Wajib memilih sekolah'),
      levelName: yup.string().required('Wajib memilih level'),
      year: yup.string().required('Wajib memilih tahun'),
    }),

    onSubmit: (values) => {
      dispatch(updateDataUser(id, values))
    },
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin Panel | User Setting Edit</title>
        <link
          rel="canonical"
          href="https://kawankelasku.com/admin/user-setting/edit"
        />
        <meta name="description" content="placeholder" />
      </Helmet>

      <div className="user-setting-edit">
        <h2>User Setting - Edit</h2>

        <div className="content-user">
          <div className="content-card">
            <div className="card-name">
              <p className="name">
                {detailUser && detailUser.fullName ? detailUser.fullName : '-'}
              </p>
              <p className="email">{detailUser?.email}</p>
            </div>
            <div className="card-school">
              <div className="icon">
                <FaGraduationCap size="35" />
              </div>
              <p className="card-item">{detailUser?.schoolName}</p>
            </div>
            <div className="card-status">
              <div className="icon">
                <FiFileText size="30" />
              </div>
              <div>
                {detailUser && detailUser.status ? 'Active' : 'NonActive'}
              </div>
            </div>
            <div className="card-action">
              <div
                className="button-delete"
                onClick={() => dispatch(deleteUser(id))}
              >
                <RiDeleteBin5Line size="25" />
              </div>
            </div>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="details">
              <FormInput
                label="fullName"
                errorMessage={formik.errors.fullName}
                error={formik.errors.fullName && formik.touched.fullName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.fullName}
                type="text"
                name="fullName"
                placeholder="Input full name"
              />
              <FormInput
                label="Email"
                errorMessage={formik.errors.email}
                error={formik.errors.email && formik.touched.email}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
                type="email"
                name="email"
                placeholder="Input email"
              />

              <div className="input-field">
                <label>Password</label>
                <div className="groupping-input">
                  <input
                    className="input-item w-100"
                    type={isShow ? 'password' : 'text'}
                    placeholder="Input password"
                    name="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                  <i onClick={() => setIsShow(!isShow)}>
                    {isShow ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </i>
                </div>
                {formik.errors.password && formik.touched.password && (
                  <div className="text-danger input-invalid">
                    {formik.errors.password}
                  </div>
                )}
              </div>

              <FormSelect
                label="School"
                data={schools.filter(item => item.status)}
                placeholder="Choose your school"
                onChange={(e) => {
                  setDataBySchool(true)
                  formik.setFieldValue(
                    'schoolId',
                    parseInt(e.target[e.target.selectedIndex].id),
                  )
                  formik.setFieldValue('schoolName', e.target.value)
                  dispatch(
                    getLevelByShoolId(e.target[e.target.selectedIndex].id),
                  )
                }}
                name="schoolName"
                value={formik.values.schoolName}
                onBlur={formik.handleBlur}
              />
              <FormSelect
                label="Level"
                data={dataBySchool ? newLevel : schoolLevelDetails}
                placeholder="Choose your level"
                onChange={(e) => {
                  formik.setFieldValue(
                    'levelId',
                    parseInt(e.target[e.target.selectedIndex].id),
                  )
                  formik.setFieldValue('levelName', e.target.value)
                }}
                name="level"
                value={formik.values.levelName}
                onBlur={formik.handleBlur}
              />
              <FormSelect
                label="Year"
                data={[
                  { id: '2006', Name: '2006' },
                  { id: '2007', Name: '2007' },
                  { id: '2008', Name: '2008' },
                  { id: '2009', Name: '2009' },
                  { id: '2010', Name: '2010' },
                  { id: '2011', Name: '2011' },
                  { id: '2012', Name: '2012' },
                  { id: '2013', Name: '2013' },
                  { id: '2014', Name: '2014' },
                  { id: '2015', Name: '2015' },
                  { id: '2016', Name: '2016' },
                  { id: '2017', Name: '2017' },
                  { id: '2018', Name: '2018' },
                  { id: '2019', Name: '2019' },
                  { id: '2020', Name: '2020' },
                  { id: '2021', Name: '2021' },
                  { id: '2022', Name: '2022' },
                  { id: '2023', Name: '2023' },
                ]}
                placeholder="Choose your year"
                value={formik.values.year}
                name="year"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorMessage={formik.errors.year}
                error={formik.errors.year && formik.touched.year}
              />
              <FormInput
                label="Whatsapp"
                errorMessage={formik.errors.whatsappNumber}
                error={
                  formik.errors.whatsappNumber && formik.touched.whatsappNumber
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.whatsappNumber}
                type="text"
                name="whatsappNumber"
                placeholder="Input whatsapp number"
              />
              <FormInput
                label="Instagram"
                errorMessage={formik.errors.instagram}
                error={formik.errors.instagram && formik.touched.instagram}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.instagram}
                type="text"
                name="instagram"
                placeholder="Input instagram"
              />

              <div className="form">
                <label>Status</label>
                <select
                  className="input-item"
                  onChange={(e) =>
                    formik.setFieldValue('status', e.target.value)
                  }
                  name="status"
                  onBlur={formik.handleBlur}
                >
                  <option hidden>Choose status</option>
                  <option value={true} selected={formik.values.status === true}>
                    Active
                  </option>
                  <option
                    value={false}
                    selected={formik.values.status === false}
                  >
                    NonActive
                  </option>
                </select>
              </div>

              <div className="button-content">
                <div className="buttons row pb-4">
                  <div className="col-4">
                    <a
                      className="btn btn-block w-100 button-cancel"
                      onClick={() => (window.location = '/admin/user-setting')}
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-8">
                    {isSubmitting ? (
                      <button
                        className="submit-button loading mt-0 w-100"
                        type="submit"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-block btn-primary w-100 button-save"
                        type="submit"
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default EditUserSetting
