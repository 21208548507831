import React from "react";
import "./form-textarea.scss";

const InputTextArea = ({
  label,
  placeholder,
  type,
  onBlur,
  onChange,
  errorMessage,
  error,
  name,
  value,
  disabled,
  className,
}) => {
  return (
    <div className={`textarea-field ${className}`}>
      <label>{label}</label>
      <textarea
        className='textarea-item'
        type={type}
        placeholder={placeholder}
        onBlur={onBlur}
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
      {error && (
        <div className='text-danger textarea-invalid'>{errorMessage}</div>
      )}
    </div>
  );
};

export default InputTextArea;
