import { APIURL } from "config";
import Swal from "sweetalert2";
import { http } from "config";

export const getAllAlumnies = (
  yearbookid,
  page,
  size,
  isFrontend,
  name = ""
) => {
  return (dispatch) => {
    http
      .get(
        `${APIURL}/yearbook/${yearbookid}/alumni?page=${page}&size=${size}&Name=${name}`
      )
      .then((res) => {
        if (res.data.code == 200) {
          dispatch({ type: "GET_ALL_ALUMNIES", payload: res.data.data });
          if (page !== 0) {
            dispatch({
              type: "ADD_PAGINATE",
              payload: {
                page: res.data.page,
                size: res.data.size,
                count: res.data.count,
              },
            });
          }
        } else {
          !isFrontend &&
            Swal.fire("Gagal", `${res.data.returnMessage}`, "error");
        }
      })
      .catch((err) => {
        dispatch({ type: "GET_ALL_ALUMNIES", payload: null });
        !isFrontend && Swal.fire("Gagal", "Tidak ada data", "error");
      });
  };
};

export const getAlumniDetail = (yearbookid, id) => {
  return (dispatch) => {
    http
      .get(`${APIURL}/yearbook/${yearbookid}/alumni/${id}`)
      .then((res) => {
        if (res.data.code == 200) {
          dispatch({ type: "GET_DETAIL_ALUMNI", payload: res.data.data });
        } else {
          Swal.fire("Gagal", `${res.data.returnMessage}`, "error");
        }
      })
      .catch((err) => {
        Swal.fire("Gagal", "Terjadi kesalahan", "error");
      });
  };
};

export const postDataAlumni = (yearbookid, data) => {
  return (dispatch) => {
    const body = {
      name: data.name,
      email: data.email,
      class: data.class,
      instagram: data.instagram,
      facebook: data.facebook,
      motto: data.motto,
      image: data.image,
      status: data.status,
    };

    http
      .post(`${APIURL}/yearbook/${yearbookid}/alumni`, body)
      .then((res) => {
        Swal.fire("Berhasil", `Berhasil menambahkan data!`, "success").then(
          () => (window.location = `/admin/alumni/${yearbookid}/list`)
        );
      })
      .catch((err) => {
        console.log("err", err);
        Swal.fire("Gagal", `Gagal menambahkan data!`, "error");
      });
  };
};

export const updateDataAlumni = (yearbookid, id, data) => {
  return (dispatch) => {
    const body = {
      name: data.name,
      email: data.email,
      class: data.class,
      instagram: data.instagram,
      facebook: data.facebook,
      motto: data.motto,
      image: data.image,
      status: data.status,
    };

    dispatch({
      type: "SET_ISSUBMITTING",
      payload: true,
    });
    http
      .put(`${APIURL}/yearbook/${yearbookid}/alumni/${id}`, body)
      .then((res) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        Swal.fire("Berhasil", `Berhasil mengubah data!`, "success").then(
          () => (window.location = `/admin/alumni/${yearbookid}/list`)
        );
      })
      .catch((err) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        console.log("err", err);
        Swal.fire("Gagal", `Gagal mengubah data!`, "error");
      });
  };
};

export const postAlumniByCsv = (yearbookid, data) => {
  return (dispatch) => {
    const body = {
      name: data,
    };

    dispatch({
      type: "SET_ISSUBMITTING",
      payload: true,
    });
    http
      .post(`${APIURL}/yearbook/${yearbookid}/alumni/upload`, body)
      .then((res) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        Swal.fire("Berhasil", `Berhasil menambahkan data!`, "success").then(
          () => (window.location = `/admin/alumni/${yearbookid}/list`)
        );
      })
      .catch((err) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        console.log("err", err);
        Swal.fire("Gagal", `Gagal menambahkan data!`, "error");
      });
  };
};

export const updateStatusAlumni = (yearbookid, id, data) => {
  return (dispatch) => {
    const body = {
      email: data.email,
      status: data.status,
    };

    http
      .put(`${APIURL}/yearbook/${yearbookid}/alumni/${id}`, body)
      .then((res) => {
        Swal.fire("Berhasil", `Berhasil mengubah data!`, "success").then(
          () => (window.location = `/admin/alumni/${yearbookid}/list`)
        );
      })
      .catch((err) => {
        console.log("err", err);
        Swal.fire("Gagal", `Gagal mengubah data!`, "error");
      });
  };
};

export const deleteAlumni = (yearbookid, id) => {
  return (dispatch) => {
    http
      .delete(`${APIURL}/yearbook/${yearbookid}/alumni/${id}`)
      .then((res) => {
        Swal.fire("Berhasil", `Berhasil menghapus data!`, "success").then(
          () => (window.location = `/admin/alumni/${yearbookid}/list`)
        );
      })
      .catch((err) => {
        console.log("err", err);
        Swal.fire("Gagal", `Gagal menghapus data!`, "error");
      });
  };
};
