import React from "react";
import { LayoutAdmin } from "components/layout";
import { Route } from "react-router-dom";

export default function Guest({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <LayoutAdmin>
            <Component {...props} />
          </LayoutAdmin>
        );
      }}
    />
  );
}
