import React, { useRef } from 'react'
import './add-school.scss'
import { FaPlus } from 'react-icons/fa'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { FormInput, FormTextarea } from 'components'
import { useFormik } from 'formik'
import { postDataSchool, postImage } from 'config/redux/action'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import * as yup from 'yup'

const AddSchool = () => {
  const inputRef = useRef()
  const { isSubmitting } = useSelector((state) => state.mainReducer)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      phone_number: '',
      school_name: '',
      address: '',
      status: true,
      levels: [
        {
          id: 0,
          name: '',
          index: 1,
          status: true,
        },
      ],
      contents: [
        {
          id: 0,
          index: 1,
          title: '',
          status: true,
          description: '',
          url: '',
        },
      ],
    },
    validationSchema: yup.object({
      school_name: yup.string().required('Masukkan nama sekolah'),
      address: yup.string().required('Masukkan alamat sekolah'),
      phone_number: yup.number().required('Masukkan nomor handphone'),
    }),

    onSubmit: (values) => {
      dispatch(postDataSchool(values))
    },
  })

  // LEVEL
  const addLevelForm = () => {
    const newLevel = {
      id: 0,
      status: true,
      index: formik.values.levels.length + 1,
      name: '',
    }
    formik.setFieldValue('levels', [...formik.values.levels, newLevel])
  }

  const deleteLevelField = (index) => {
    if (formik.values.levels.length > 1) {
      const newLevels = formik.values.levels.filter(
        (level) => level.index !== index,
      )
      formik.setFieldValue('levels', newLevels)
    }
  }

  // CONTENT
  const addContentForm = () => {
    const newLevel = {
      id: 0,
      index: formik.values.contents.length + 1,
      title: '',
      status: true,
      description: '',
      url: '',
    }
    formik.setFieldValue('contents', [...formik.values.contents, newLevel])
  }

  const deleteContentField = (index) => {
    if (formik.values.contents.length > 1) {
      const newContents = formik.values.contents.filter(
        (content) => content.index !== index,
      )
      formik.setFieldValue('contents', newContents)
    }
  }

  const onBtnClick = () => {
    inputRef.current.click()
  }

  const testUpload = async (image, index) => {
    const data = await postImage(image)
    formik.setFieldValue(
      `contents[${index}].url`,
      `https://kawankelasku.com/api/${data?.path}`,
    )
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin Panel | School Add</title>
        <link
          rel="canonical"
          href="https://kawankelasku.com/admin/school/add"
        />
        <meta name="description" content="placeholder" />
      </Helmet>

      <div className="add-school">
        <h2>School - Add New</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="content-school">
            <div className="details">
              <FormInput
                label="Phone Number"
                errorMessage={formik.errors.phone_number}
                error={
                  formik.errors.phone_number && formik.touched.phone_number
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="phone_number"
                placeholder="Input Phone Number"
              />
              <FormInput
                label="School Name"
                errorMessage={formik.errors.school_name}
                error={formik.errors.school_name && formik.touched.school_name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="school_name"
                placeholder="Input School Name"
              />
              <FormInput
                label="Address"
                errorMessage={formik.errors.address}
                error={formik.errors.address && formik.touched.address}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="address"
                placeholder="Input School Address"
              />
              <div className="levels">
                <label>Level</label>
                {formik.values.levels.map((level, index) => (
                  <div
                    className="items d-flex align-items-center justify-content-between"
                    key={index}
                  >
                    <div
                      className="level-name"
                      style={{ flex: 1, marginRight: 10 }}
                    >
                      <FormInput
                        noLabel
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name={`levels[${index}].name`}
                        value={level.name}
                        placeholder={`Input level ${index + 1}`}
                      />
                    </div>
                    <RiDeleteBin5Line
                      size="25"
                      style={{ marginBottom: 16, cursor: 'pointer' }}
                      onClick={() => deleteLevelField(level.index)}
                    />
                  </div>
                ))}
                <div className="add-new-level" onClick={addLevelForm}>
                  Add New Level
                </div>
              </div>

              <div className="profile-content">
                <label>Profile Content</label>
                <div className="cards">
                  {formik.values?.contents?.map((content, index) => (
                    <div className="content-card-school" key={`pc-${index}`}>
                      {formik.values.contents[index].url ? (
                        <img
                          className="preview-content"
                          src={formik.values.contents[index].url}
                          alt={`Gambar ${index + 1}`}
                        />
                      ) : (
                        <div className="input-plus" onClick={onBtnClick}>
                          <FaPlus />
                        </div>
                      )}
                      <input
                        type="file"
                        ref={inputRef}
                        className="d-none"
                        name={`contents[${index}].url`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onChange={(e) => testUpload(e.target.files[0], index)}
                      />
                      <div className="details mt-2">
                        <div className="item">
                          <FormInput
                            label="Title"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={`contents[${index}].title`}
                            placeholder="Input title"
                          />
                        </div>
                        <div className="item">
                          <FormTextarea
                            label="Description"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            name={`contents[${index}].description`}
                            placeholder="Input Description"
                          />
                        </div>

                        <div className="icon-trash">
                          <RiDeleteBin5Line
                            size="20"
                            color="#0F75BC"
                            onClick={() => deleteContentField(content.index)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="add-new-content" onClick={addContentForm}>
                  Add New Content
                </div>

                <div className="buttons row pb-4">
                  <div className="col-4">
                    <a
                      className="btn btn-block w-100 button-cancel"
                      onClick={() => (window.location = '/admin/school')}
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-8">
                    {isSubmitting ? (
                      <button
                        className="submit-button loading mt-0 w-100"
                        type="submit"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-block btn-primary w-100 button-save"
                        type="submit"
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddSchool
