import React, { useEffect } from "react";
import "./user-setting.scss";
import { Helmet } from "react-helmet";
import { FaGraduationCap, FaPencilAlt } from "react-icons/fa";
import { FiFileText } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { deleteUser, getAllActiveUsers } from "config/redux/action";
import { Loading, Pagination } from "components";
import { MdOutlineClass } from "react-icons/md";
import { BsDot } from "react-icons/bs";

const UserSetting = () => {
  const { activeUsers } = useSelector((state) => state.userReducer);
  const { paginate } = useSelector((state) => state.mainReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllActiveUsers(1, paginate.size));
  }, []);

  const toPage = (page) => {
    dispatch(getAllActiveUsers(page, paginate.size));
  };

  if (!activeUsers) {
    return (
      <div className='user-setting'>
        <h1>User Setting</h1>
        <h2>Tidak ada data..</h2>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Admin Panel | User Setting</title>
        <link
          rel='canonical'
          href='https://kawankelasku.com/admin/user-setting'
        />
        <meta name='description' content='placeholder' />
      </Helmet>

      {activeUsers.length === 0 ? (
        <Loading />
      ) : (
        <div className='user-setting'>
          <h1>User Setting</h1>
          {activeUsers?.map((val, i) => {
            return (
              <div className='content-card' key={`data-${i}`}>
                <div className='card-name'>
                  <p className='name'>{val.fullName ? val.fullName : "-"}</p>
                  <p className='email'>{val.email}</p>
                </div>
                <div className='card-school'>
                  <div className='icon'>
                    <FaGraduationCap size='35' />
                  </div>
                  <p className='card-item'>
                    {val.schoolName ? val.schoolName : "-"}
                  </p>
                </div>
                <div className='card-school'>
                  <div className='icon'>
                    <MdOutlineClass size='35' />
                  </div>
                  <p className='card-item'>
                    {val.levelName ? val.levelName : "-"} <BsDot /> <span className='year'>{val.year}</span>
                  </p>
                </div>
                <div className='card-status'>
                  <div className='icon'>
                    <FiFileText size='30' />
                  </div>
                  <div>{val.status ? "Active" : "NonActive"}</div>
                </div>
                <div className='card-action'>
                  <div
                    className='button-delete'
                    onClick={() => dispatch(deleteUser(val.id))}>
                    <RiDeleteBin5Line size='25' />
                  </div>
                  <div className='button-edit'>
                    <FaPencilAlt
                      size='25'
                      onClick={() =>
                        history.push(`/admin/user-setting/edit/${val.id}`)
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}

          {activeUsers.length !== 0 && (
            <Pagination
              onClick={toPage}
              page={paginate.count / paginate.size}
            />
          )}
        </div>
      )}
    </>
  );
};

export default UserSetting;
