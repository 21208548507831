import React from "react";
import "./loading.scss";

const Loading = () => {
  return (
    <div className='loading-screen'>
      <span
        className='spinner-border spinner-border-lg'
        role='status'
        aria-hidden='true'></span>
    </div>
  );
};

export default Loading;
