import React from "react";
import { SuccessRegister } from "assets";
import "./register.scss";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";
// import { useSelector } from "react-redux";
// import { useEffect } from "react";

const RegisterSuccess = () => {
  // const { user } = useSelector((state) => state.loginReducer);
  const history = useHistory();

  // useEffect(() => {
  //   if (!user) {
  //     history.push("/login");
  //   }
  // }, []);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Yearbook Apps | Register Success</title>
        <link rel='canonical' href='https://kawankelasku.com/register-success' />
        <meta name='description' content='placeholder' />
      </Helmet>
      <div className='card-content text-center'>
        <div className='content-register'>
          <h2 className='title'>Register Success</h2>
          <div className='success-card'>
            <img src={SuccessRegister} alt='' height='100' width='99' />
            <p>
              Your data will be reviewed by us first. when it's done we'll let
              you know via
            </p>
            <a
              href={`https://api.whatsapp.com/send/?phone=%2B6281282592178&text=Halo+saya+ingin+bertanya&app_absent=0`}
              target="_blank"
            >
              Whatsapp
            </a>
          </div>
        </div>
      </div>
      <div className='card-item mt-2'>
        Already have an account ?{" "}
        <a onClick={() => history.push("/login")}>Sign In</a>
      </div>
    </>
  );
};

export default RegisterSuccess;
