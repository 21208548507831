import React, { useEffect } from "react";
import "./detail-alumni.scss";
import { FaGraduationCap, FaFacebook } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { AiFillInstagram } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getAlumniDetail,
} from "config/redux/action";

const DetailAlumniFE = () => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.loginReducer);
  const { detailAlumni } = useSelector((state) => state.alumniReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAlumniDetail(user.YearbookId, id))
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Yearbook Apps | Alumni Detail</title>
        <link rel='canonical' href='https://kawankelasku.com/alumni' />
        <meta name='description' content='placeholder' />
      </Helmet>
      <div className='detail-alumni'>
        <div className='wrapper-content'>
          <img src={detailAlumni?.image} alt='' width='400' height='600' />
          <div className='info'>
            <div className='details'>
              <h2>{detailAlumni?.name}</h2>
              <div className='detail-info'>
                <div className='icon'>
                  <FaGraduationCap size='27' />
                </div>
                <p>{detailAlumni?.class}</p>
              </div>
              <div className='detail-info'>
                <div className='icon'>
                  <MdEmail size='25' />
                </div>
                <p>{detailAlumni?.email}</p>
              </div>
              <div className='detail-info'>
                <div className='icon'>
                  <AiFillInstagram size='26' />
                </div>
                <p>{detailAlumni?.instagram ? detailAlumni.instagram : '-'}</p>
              </div>
              <div className='detail-info'>
                <div className='icon'>
                  <FaFacebook size='25' />
                </div>
                <p>{detailAlumni?.facebook ? detailAlumni.facebook : '-'}</p>
              </div>
            </div>

            <div className='motto'>
              <p>
                “{detailAlumni?.motto ? detailAlumni.motto : '-'}”
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailAlumniFE;
