import { APIURL } from "config";
import Swal from "sweetalert2";
import { http } from "config";

export const getAllGalleries = (page, size) => {
  return (dispatch) => {
    http
      .get(`${APIURL}/gallery?page=${page}&size=${size}`)
      .then((res) => {
        if (res.data.code == 200) {
          dispatch({ type: "GET_ALL_GALLERIES", payload: res.data.data });
        } else {
          Swal.fire("Gagal", `${res.data.returnMessage}`, "error");
        }
      })
      .catch((err) => {
        Swal.fire("Gagal", "Tidak ada data", "error");
      });
  };
};

export const getGalleryDetail = (id) => {
  return (dispatch) => {
    http
      .get(`${APIURL}/gallery/${id}`)
      .then((res) => {
        if (res.data.code == 200) {
          dispatch({ type: "GET_DETAIL_GALLERY", payload: res.data.data });
        } else {
          Swal.fire("Gagal", `${res.data.returnMessage}`, "error");
        }
      })
      .catch((err) => {
        Swal.fire("Gagal", "Terjadi kesalahan", "error");
      });
  };
};

export const updateDataGallery = (id, status, data) => {
  return (dispatch) => {
    const body = {
      status: status,
      images: data.images,
    };

    dispatch({
      type: "SET_ISSUBMITTING",
      payload: true,
    });

    http
      .post(`${APIURL}/yearbook/${id}/gallery`, body)
      .then((res) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        Swal.fire("Berhasil", `Berhasil mengubah data!`, "success").then(
          () => (window.location = `/admin/gallery`)
        );
      })
      .catch((err) => {
        dispatch({
          type: "SET_ISSUBMITTING",
          payload: false,
        });
        console.log("err", err);
        Swal.fire("Gagal", `Gagal mengubah data!`, "error");
      });
  };
};

export const getYearbookGallery = (id) => {
  return (dispatch) => {
    http
      .get(`${APIURL}/yearbook/${id}/gallery`)
      .then((res) => {
        if (res.data.code == 200) {
          const splitArrayBy5Items = () => {
            const items = [];
            const newArr = res.data.data?.images?.slice();
            for (let i = newArr?.length - 1; i > 0; i--) {
              const rand = Math.floor(Math.random() * (i + 1));
              [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
            }
            for (let i = 0; i < newArr?.length; i += 5) {
              items.push(newArr.slice(i, i + 5));
            }

            return items;
          };

          dispatch({ type: "SET_YEARBOOK_GALLERY", payload: res.data.data });
          dispatch({ type: "SET_IMAGES", payload: splitArrayBy5Items() });
        } else {
          Swal.fire("Gagal", `${res.data.returnMessage}`, "error");
        }
      })
      .catch((err) => {
        Swal.fire("Gagal", "Tidak ada data", "error");
      });
  };
};
