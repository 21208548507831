const initialState = {
  gallery: [],
  detailGallery: null,
  yearbookGallery: [],
  images: [],
};

const GalleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_GALLERIES":
      return {
        ...state,
        gallery: action.payload,
      };
    case "GET_DETAIL_GALLERY":
      return {
        ...state,
        detailGallery: action.payload,
      };
    case "SET_YEARBOOK_GALLERY":
      return {
        ...state,
        yearbookGallery: action.payload,
      };
    case "SET_IMAGES":
      return {
        ...state,
        images: action.payload,
      };
    default:
      return state;
  }
};

export default GalleryReducer;
