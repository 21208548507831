import { AuthBgLeft, AuthBgRight, AuthTitle } from "assets";
import { FloatingWA } from "components";
import React from "react";
import "./layout-auth.scss";

const LayoutAuth = ({ children }) => {
  return (
    <div className='layout-auth'>
      <img className='left-bg' src={AuthBgLeft} alt='logo' />
      <div className='wrapper-card'>
        <img className='content-title' src={AuthTitle} alt='' />
        {children}
      </div>
      <img className='right-bg' src={AuthBgRight} alt='logo' />

      <FloatingWA />
    </div>
  );
};

export default LayoutAuth;
