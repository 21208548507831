import React from "react";
import { Helmet } from "react-helmet";

const Profile = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Yearbook Apps</title>
        <link rel='canonical' href='https://kawankelasku.com' />
        <meta
          name='description'
          content='Aplikasi Yearbook, ini cuma placeholder meta description dummy saja hanya untuk test SEO hehe'
        />
      </Helmet>
      <div>
        <h1>KONTEN</h1>
      </div>
    </>
  );
};

export default Profile;
