import { Whatsapp_Icon } from "assets";
import React from "react";
import { useLocation } from "react-router";
import "./floating-whatsapp.scss";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const Whatsapp = () => {

  const params = useLocation().pathname.split("/")[1];

  return (
    <>
      {isMobile ? <div>
        {params === 'yearbook' ? <a
          href={`https://api.whatsapp.com/send/?phone=%2B6281282592178&text=Halo+saya+ingin+bertanya&app_absent=0`}
          target="_blank"
        >
          {/* <div className='help'>Need Help ?</div> */}
          <img className='floating-whatsapp-yearbook' src={Whatsapp_Icon} alt='whatsapp' />
        </a> : params === 'alumni' ? <a
          href={`https://api.whatsapp.com/send/?phone=%2B6281282592178&text=Halo+saya+ingin+bertanya&app_absent=0`}
          target="_blank"
        >
          {/* <div className='help'>Need Help ?</div> */}
          <img className='floating-whatsapp-alumni' src={Whatsapp_Icon} alt='whatsapp' />
        </a> : params === 'teacher' ? <a
          href={`https://api.whatsapp.com/send/?phone=%2B6281282592178&text=Halo+saya+ingin+bertanya&app_absent=0`}
          target="_blank"
        >
          {/* <div className='help'>Need Help ?</div> */}
          <img className='floating-whatsapp-teacher' src={Whatsapp_Icon} alt='whatsapp' />
        </a> : <a
          href={`https://api.whatsapp.com/send/?phone=%2B6281282592178&text=Halo+saya+ingin+bertanya&app_absent=0`}
          target="_blank"
        >
          {/* <div className='help'>Need Help ?</div> */}
          <img className='floating-whatsapp-galery' src={Whatsapp_Icon} alt='whatsapp' />
        </a>} 
      </div> : ''}

      {isBrowser ? <a
        href={`https://api.whatsapp.com/send/?phone=%2B6281282592178&text=Halo+saya+ingin+bertanya&app_absent=0`}
        target="_blank"
      >
        {/* <div className='help'>Need Help ?</div> */}
        <img className='floating-whatsapp' src={Whatsapp_Icon} alt='whatsapp' />
      </a> : '' }
     

     
     
    </>
  );
};

export default Whatsapp;
