import React, { useState } from "react";
import "./layout-profil.scss";
import { FaUser, FaRegCalendarAlt, FaArrowLeft } from "react-icons/fa";
import { useHistory, useLocation } from "react-router";
import { Button, Offcanvas } from "react-bootstrap";
import { FloatingWA } from "components";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllAlumnies, getAllTeachers, logout } from "config/redux/action";
import { useEffect } from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { AiOutlineUnorderedList } from "react-icons/ai";

const LayoutProfile = ({ children }) => {
  const { user } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useLocation().pathname.split("/")[1];
  const isShow = useLocation().pathname.split("/")[2];
  const backHome = useLocation().pathname.split("/");
  const [search, setSearch] = useState("");

  //mobile 
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setSearch("");
    if (!user) {
      history.push("/login");
    } else if (user.RoleId !== 1) {
      history.push("/admin/user-approval");
    } else if (user.Status === false) {
      history.push("/register-success");
    }
  }, []);

  const menus = [
    {
      id: 1,
      name: "Yearbook",
      link: "yearbook",
    },
    {
      id: 2,
      name: "Alumni",
      link: "alumni",
    },
    {
      id: 3,
      name: "Teacher",
      link: "teacher",
    },
    {
      id: 4,
      name: "Gallery",
      link: "gallery",
    },
  ];

  const onHandleSearch = () => {
    if (params === "alumni") {
      dispatch(
        getAllAlumnies(user.YearbookId, 1, 99999999, { isFrontend: true }, search)
      );
    } else if (params === "teacher") {
      dispatch(
        getAllTeachers(user.YearbookId, 1, 99999999, { isFrontend: true }, search)
      );
    }
  };

  return (
    <>
    {isMobile ? <div>
        {/* <Button variant="primary" className="btn-profile" onClick={handleShow}>
          Profile
        </Button> */}

        <div 
            className="arrow"
            onClick={handleShow}>
            <AiOutlineUnorderedList size={30}/>
        </div> 

        

        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className='layout-profil'>
              <div className='sidebar-image'>
                <div className='wrapper-card'>
                  <div className='card-info text-center'>
                    <div className='icon-user'>
                      <FaUser />
                    </div>
                    <p className='nama'>{user?.FullName}</p>
                    <p className='sekolah'>{user?.SchoolName}</p>
                    <p className='sekolah'>{user?.LevelName}</p>
                    <div className='tanggal'>
                      <FaRegCalendarAlt />
                      <p className='mb-0'>{user?.Year}</p>
                    </div>
                  </div>
                  <div className='card-logout'>
                    <a
                      className='btn btn-logout btn-block w-100'
                      onClick={() => dispatch(logout())}>
                      LOGOUT
                    </a>
                  </div>
                </div>
              </div>
            </div>      
          </Offcanvas.Body>
        </Offcanvas>
        <div className='layout-profil'>
          <div className='content'>
            {params !== "about-school" && (
              <div className='top-bar'>
                <div className='back-button' onClick={backHome.length === 3 ? () => history.goBack() : () => history.push("/about-school")}>
                  <div className='icon-back'>
                    <FaArrowLeft />
                  </div>
                  <p>Back</p>
                </div>

                {!isShow && (
                  <div className='top-menus'>
                    <div className='menus'>
                      {menus?.map((val, i) => (
                        <Link
                          key={`feMenu-${i}`}
                          to={`/${val.link}`}
                          className={`${params === val.link && "active"}`}>
                          {val.name}
                        </Link>
                      ))}
                    </div>

                    {(params === "teacher" || params === "alumni") && (
                      <div className='search'>
                        <input
                          type='text'
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder='Search what you need here'
                        />
                        <div
                          className='button-search btn btn-primary text-white'
                          onClick={onHandleSearch}>
                          Search
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {children}
          </div>
        </div>
    </div> : ''}
      {isBrowser ? <div className='layout-profil'>
        <div className='sidebar-image'>
          <div className='wrapper-card'>
            <div className='card-info text-center'>
              <div className='icon-user'>
                <FaUser />
              </div>
              <p className='nama'>{user?.FullName}</p>
              <p className='sekolah'>{user?.SchoolName}</p>
              <p className='sekolah'>{user?.LevelName}</p>
              <div className='tanggal'>
                <FaRegCalendarAlt />
                <p className='mb-0'>{user?.Year}</p>
              </div>
            </div>
            <div className='card-logout'>
              <a
                className='btn btn-logout btn-block w-100'
                onClick={() => dispatch(logout())}>
                LOGOUT
              </a>
            </div>
          </div>
        </div>
        <div className='content'>
          {params !== "about-school" && (
            <div className='top-bar'>
              <div className='back-button' onClick={backHome.length === 3 ? () => history.goBack() : () => history.push("/about-school")}>
                <div className='icon-back'>
                  <FaArrowLeft />
                </div>
                <p>Back</p>
              </div>

              {!isShow && (
                <div className='top-menus'>
                  <div className='menus'>
                    {menus?.map((val, i) => (
                      <Link
                        key={`feMenu-${i}`}
                        to={`/${val.link}`}
                        className={`${params === val.link && "active"}`}>
                        {val.name}
                      </Link>
                    ))}
                  </div>

                  {(params === "teacher" || params === "alumni") && (
                    <div className='search'>
                      <input
                        type='text'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder='Search what you need here'
                      />
                      <div
                        className='button-search btn btn-primary text-white'
                        onClick={onHandleSearch}>
                        Search
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {children}
        </div>
      </div> : '' }
      

      <FloatingWA />
    </>
  );
};

export default LayoutProfile;
