import React from 'react'
import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { useSelector } from 'react-redux'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFReader = () => {
  const { pdfYearbook } = useSelector((state) => state.utilsReducer)
  const [numPages, setNumPages] = useState(null)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  return (
    <div className="pdf-viewer-page">
      <Document
        file={pdfYearbook}
        options={{ workerSrc: '/pdf.worker.js' }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </div>
  )
}

export default PDFReader
