import React, { useEffect } from "react";
import "./gallery.scss";
import { Helmet } from "react-helmet";
import { FaPencilAlt } from "react-icons/fa";
import { FiFileText } from "react-icons/fi";
// import { RiDeleteBin5Line } from "react-icons/ri";
import { BiPhotoAlbum } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  // deleteYearbook,
  getAllYearbooks,
} from "config/redux/action";
import { Loading, Pagination } from "components";

const Gallery = () => {
  const { yearbook } = useSelector((state) => state.yearbookReducer);
  const { paginate } = useSelector((state) => state.mainReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllYearbooks(1, 5));
  }, []);

  const toPage = (page) => {
    dispatch(getAllYearbooks(page, paginate.size));
  };

  if (!yearbook) {
    return (
      <div className='gallery-list'>
        <div className='wrapper-button'>
          <h1>Gallery</h1>
        </div>
        <h2>Tidak ada data..</h2>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Admin Panel | Gallery</title>
        <link rel='canonical' href='https://kawankelasku.com/admin/gallery' />
        <meta name='description' content='placeholder' />
      </Helmet>

      {yearbook.length === 0 ? (
        <Loading />
      ) : (
        <div className='gallery-list'>
          <div className='wrapper-button'>
            <h1>Gallery</h1>
          </div>

          {yearbook.map((val, i) => (
            <div className='content-card' key={`data-${i}`}>
              <div className='card-name'>
                <p className='name'>{val.name}</p>
                <p className='code'>yr0{val.id}</p>
              </div>
              <div className='card-photo'>
                <div className='icon'>
                  <BiPhotoAlbum size='30' />
                </div>
                <p className='card-item'>{val.totalImages} Photos</p>
              </div>
              <div className='card-status'>
                <div className='icon'>
                  <FiFileText size='30' />
                </div>
                <div>{val.status ? "Active" : "NonActive"}</div>
              </div>
              <div className='card-action'>
                {/* <div
                className='button-delete'
                onClick={() => dispatch(deleteYearbook(val.id))}
              >
                <RiDeleteBin5Line size='25' />
              </div> */}
                <div className='button-edit'>
                  <FaPencilAlt
                    size='25'
                    onClick={() =>
                      history.push(`/admin/gallery/edit/${val.id}`)
                    }
                  />
                </div>
              </div>
            </div>
          ))}

          <Pagination onClick={toPage} page={paginate.count / paginate.size} />
        </div>
      )}
    </>
  );
};

export default Gallery;
