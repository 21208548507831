import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {
  NotFound,
  Homepage,
  Login,
  Profile,
  Register,
  // Admin,
  UserApproval,
  UserSetting,
  School,
  YearBook,
  EditYearbook,
  Alumni,
  AlumniList,
  AddAlumni,
  EditAlumni,
  UploadAlumni,
  Teacher,
  TeacherList,
  AddTeacher,
  EditTeacher,
  UploadTeacher,
  Gallery,
  EditGallery,
  LoginAdmin,
  RegisterSuccess,
  EditSchool,
  EditUserSetting,
  AddSchool,
  AboutSchool,
  AddYearbook,
  TeacherFE,
  YearbookFE,
  AlumniFE,
  GalleryFE,
  DetailAlumniFE,
  DetailTeacherFE,
  Welcome,
  PDFReader,
} from 'pages'
import RouteProfil from 'routes/route-profil'
// import RouteWeb from "./route-web";
import RouteAdmin from './route-admin'
import RouteAuth from './route-auth'

const Routex = () => {
  return (
    <>
      <Switch>
        <RouteAuth exact path="/login" component={Login} />
        <RouteAuth exact path="/welcome" component={Welcome} />
        <RouteAuth exact path="/register" component={Register} />
        <RouteAuth exact path="/register-success" component={RegisterSuccess} />
        <Route exact path="/" component={Homepage} />
        <Route exact path="/yearbook/pdf" component={PDFReader} />
        <Route exact path="/administrator" component={LoginAdmin} />

        {/* ADMIN ROUTES */}
        {/* <RouteAdmin exact path='/admin' component={Admin} /> */}
        <RouteAdmin
          exact
          path="/admin/user-approval"
          component={UserApproval}
        />
        <RouteAdmin exact path="/admin/user-setting" component={UserSetting} />
        <RouteAdmin
          exact
          path="/admin/user-setting/edit/:id"
          component={EditUserSetting}
        />
        <RouteAdmin exact path="/admin/school" component={School} />
        <RouteAdmin
          exact
          path="/admin/school/edit/:id"
          component={EditSchool}
        />
        <RouteAdmin exact path="/admin/school/add" component={AddSchool} />
        <RouteAdmin exact path="/admin/yearbook" component={YearBook} />
        <RouteAdmin exact path="/admin/yearbook/add" component={AddYearbook} />
        <RouteAdmin
          exact
          path="/admin/yearbook/edit/:id"
          component={EditYearbook}
        />
        <RouteAdmin exact path="/admin/alumni" component={Alumni} />
        <RouteAdmin
          exact
          path="/admin/alumni/:yearbookid/list"
          component={AlumniList}
        />
        <RouteAdmin
          exact
          path="/admin/alumni/:yearbookid/add"
          component={AddAlumni}
        />
        <RouteAdmin
          exact
          path="/admin/alumni/:yearbookid/list/:id/detail"
          component={EditAlumni}
        />
        <RouteAdmin
          exact
          path="/admin/alumni/:yearbookid/upload"
          component={UploadAlumni}
        />
        <RouteAdmin exact path="/admin/teacher" component={Teacher} />
        <RouteAdmin
          exact
          path="/admin/teacher/:yearbookid/list"
          component={TeacherList}
        />
        <RouteAdmin
          exact
          path="/admin/teacher/:yearbookid/add"
          component={AddTeacher}
        />
        <RouteAdmin
          exact
          path="/admin/teacher/:yearbookid/list/:id/detail"
          component={EditTeacher}
        />
        <RouteAdmin
          exact
          path="/admin/teacher/:yearbookid/upload"
          component={UploadTeacher}
        />
        <RouteAdmin exact path="/admin/gallery" component={Gallery} />
        <RouteAdmin
          exact
          path="/admin/gallery/edit/:id"
          component={EditGallery}
        />

        {/* ROUTE PROFIL */}
        <RouteProfil exact path="/about-school" component={AboutSchool} />
        <RouteProfil exact path="/profile" component={Profile} />
        <RouteProfil exact path="/teacher" component={TeacherFE} />
        <RouteProfil exact path="/teacher/:id" component={DetailTeacherFE} />
        <RouteProfil exact path="/yearbook" component={YearbookFE} />
        <RouteProfil exact path="/alumni" component={AlumniFE} />
        <RouteProfil exact path="/alumni/:id" component={DetailAlumniFE} />
        <RouteProfil exact path="/gallery" component={GalleryFE} />

        <Route component={NotFound} />
      </Switch>
    </>
  )
}

export default Routex
