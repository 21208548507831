import React, { useEffect } from "react";
import "./teacher.scss";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getAllYearbooks } from "config/redux/action";
import { FormSelect } from "components";
import { useFormik } from "formik";

const Teacher = () => {
  const { yearbook } = useSelector((state) => state.yearbookReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllYearbooks(0, 0));
  }, []);

  const formik = useFormik({
    initialValues: {
      yearbookid: "",
      name: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Wajib memilih yearbook"),
    }),

    onSubmit: (values) => {
      history.push(`/admin/teacher/${values.yearbookid}/list`);
    },
  });

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Admin Panel | Teacher</title>
        <link rel='canonical' href='https://kawankelasku.com/admin/teacher' />
        <meta name='description' content='placeholder' />
      </Helmet>

      <div className='yearbook-teacher-list'>
        <h1>Teacher</h1>
        <div className='content-card'>
          <form onSubmit={formik.handleSubmit} className='input-group'>
            <FormSelect
              label='Yearbook'
              data={yearbook}
              placeholder='Choose yearbook'
              onChange={(e) => {
                formik.setValues({
                  ...formik.values,
                  name: e.target.value,
                  yearbookid: e.target[e.target.selectedIndex].id,
                });
              }}
              name='yearbook'
              value={formik.values.name}
              onBlur={formik.onBlur}
            />

            <button className='submit-button' type='submit'>
              Confirm
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Teacher;
