import React, { useEffect } from 'react'
import './user-approval.scss'
import { Helmet } from 'react-helmet'
import {
  FaCalendar,
  FaFacebook,
  FaGraduationCap,
  FaIdBadge,
  FaInstagramSquare,
  FaRegCalendarAlt,
  FaUnlockAlt,
  FaWhatsapp,
} from 'react-icons/fa'
import { FiChevronDown, FiMoreHorizontal } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsers, approveRejectUser } from 'config/redux/action'
import { Loading, Pagination } from 'components'
import { useState } from 'react'

const UserApproval = () => {
  const { users } = useSelector((state) => state.userReducer)
  const { paginate } = useSelector((state) => state.mainReducer)
  const { isSubmitting } = useSelector((state) => state.mainReducer)
  const dispatch = useDispatch()
  const [toggleActive, setToggleActive] = useState()
  const [toggleReject, setToggleReject] = useState(false)

  useEffect(() => {
    dispatch(getAllUsers(1, paginate.size))
  }, [])

  const toPage = (page) => {
    dispatch(getAllUsers(page, paginate.size))
  }

  const handleToggleReject = (id) => {
    if (toggleReject === id) {
      setToggleReject(false)
    } else {
      setToggleReject(id)
    }
  }

  if (!users) {
    return (
      <div className="user-approval">
        <h1>User Approval</h1>
        <h2>Tidak ada data..</h2>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin Panel | User Approval</title>
        <link
          rel="canonical"
          href="https://kawankelasku.com/admin/user-approval"
        />
        <meta name="description" content="placeholder" />
      </Helmet>

      {users.length === 0 ? (
        <Loading />
      ) : (
        <div className="user-approval">
          <h1>User Approval</h1>

          {users?.map((val, i) => {
            let userWhatsapp = ''
            if (val?.whatsappNumber) {
              userWhatsapp = val?.whatsappNumber.substring(1)
            }

            return (
              <div className="content-card" key={`data-${i}`}>
                <div className="top">
                  <div className="card-name">
                    <p className="name">{val.fullName ? val.fullName : '-'}</p>
                    <p className="email">{val.email}</p>
                  </div>
                  <div className="card-school">
                    <div className="icon">
                      <FaGraduationCap size="35" />
                    </div>
                    <p className="card-item">
                      {val.schoolName ? val.schoolName : '-'}
                    </p>
                  </div>
                  <div className="card-date">
                    <div className="icon">
                      <FaRegCalendarAlt size="30" />
                    </div>
                    <p className="card-item">{val.createdAt}</p>
                  </div>
                  <div className="card-action">
                    <div
                      className={`button-more ${
                        toggleActive === val.id && `active`
                      }`}
                    >
                      <FiMoreHorizontal
                        size="50"
                        onClick={() =>
                          toggleActive === val.id
                            ? setToggleActive()
                            : setToggleActive(val.id)
                        }
                      />
                    </div>
                    <div className="buttons">
                      {isSubmitting ? (
                        <div className="button-action" style={{ opacity: 0.5 }}>
                          <div
                            class="spinner-border mx-auto spinner-border-sm"
                            role="status"
                          ></div>
                          {/* <FiChevronDown size='30' /> */}
                        </div>
                      ) : (
                        <div className="button-action">
                          <p
                            className="mb-0"
                            onClick={() =>
                              dispatch(approveRejectUser(val.email, true))
                            }
                          >
                            Approve
                          </p>{' '}
                          <FiChevronDown
                            size="30"
                            onClick={() => handleToggleReject(val.id)}
                          />
                        </div>
                      )}

                      {toggleReject === val.id && (
                        <>
                          {isSubmitting ? (
                            <div
                              className="button-reject"
                              style={{ opacity: 0.8 }}
                            >
                              <div
                                class="spinner-border mx-auto spinner-border-sm"
                                role="status"
                              ></div>
                            </div>
                          ) : (
                            <div
                              className="button-reject"
                              onClick={() =>
                                dispatch(approveRejectUser(val.email, false))
                              }
                            >
                              <p className="mb-0">Reject</p>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {toggleActive === val.id && (
                  <div className="bottom">
                    <div className="row">
                      <div className="col-md-4 text-center mb-3">
                        <div className="id-siswa">
                          <p>
                            <FaUnlockAlt />{' '}
                            {val.phoneNumber ? val.phoneNumber : '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 text-center mb-3">
                        <div className="level-siswa">
                          <p>
                            <FaIdBadge /> {val.levelName ? val.levelName : '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 text-center mb-3">
                        <div className="tahun-siswa">
                          <p>
                            <FaCalendar /> {val.year ? val.year : '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 text-center mb-3">
                        <div className="whatsapp-siswa">
                          <a
                            href={`https://api.whatsapp.com/send/?phone=%2B62${userWhatsapp}&text=Halo+saya+ingin+bertanya&app_absent=0`}
                            target="_blank"
                          >
                            <FaWhatsapp />{' '}
                            {val.whatsappNumber ? val.whatsappNumber : '-'}
                          </a>
                        </div>
                      </div>
                      <div className="col-md-4 text-center mb-3">
                        <div className="instagram-siswa">
                          <p>
                            <FaInstagramSquare />{' '}
                            {val.instagram ? val.instagram : '-'}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 text-center mb-3">
                        <div className="nama-siswa">
                          <p>
                            <FaFacebook /> {val.facebook ? val.facebook : '-'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          })}

          <Pagination onClick={toPage} page={paginate.count / paginate.size} />
        </div>
      )}
    </>
  )
}

export default UserApproval
