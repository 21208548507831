const initialState = {
  yearbook: [],
  yearbookLvlSchool: [],
  detailYearbook: null,
  yearbookById: null,
};

const YearbookReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_YEARBOOKS":
      return {
        ...state,
        yearbook: action.payload
      };
    case "GET_YEARBOOK_BY_ID":
      return {
        ...state,
        yearbookById: action.payload
      };
    case "GET_DETAIL_YEARBOOK":
      return {
        ...state,
        detailYearbook: action.payload,
      };
    case "GET_YEARBOOK_LVL_SCHOOL":
      return {
        ...state,
        yearbookLvlSchool: action.payload,
      };
    default:
      return state;
  }
};

export default YearbookReducer;