import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import mainReducer from "./mainReducer";
import userReducer from "./userReducer";
import adminReducer from "./adminReducer";
import yearbookReducer from "./yearbookReducer";
import galleryReducer from "./galleryReducer";
import alumniReducer from "./alumniReducer";
import teacherReducer from "./teacherReducer";
import utilsReducer from "./utilsReducer";

const reducer = combineReducers({
  loginReducer,
  mainReducer,
  userReducer,
  adminReducer,
  yearbookReducer,
  galleryReducer,
  alumniReducer,
  teacherReducer,
  utilsReducer,
});

export default reducer;
