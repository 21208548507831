import React from "react";
import "./welcome.scss";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllSchools, logout } from "config/redux/action";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { FormSelect } from "components";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";

const Welcome = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.loginReducer);
  const { schools } = useSelector((state) => state.mainReducer);

  useEffect(() => {
    !user && history.goBack();
    dispatch(getAllSchools(0, 0));
  }, []);

  const formik = useFormik({
    initialValues: {
      schoolId: "",
      schoolName: "",
    },
    validationSchema: yup.object({
      schoolId: yup.string().required("Wajib memilih sekolah")
    }),

    onSubmit: (values) => {
      submitLogin(values);
    },
  });

  const submitLogin = (values) => {
    let valid = user?.schoolId === values.schoolId
    if (valid) {
      history.push(`/about-school`);
    } else {
      Swal.fire("Gagal", "Anda tidak terdaftar disekolah tersebut", "error");
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Welcome | Yearbook Apps</title>
        <link rel='canonical' href='https://kawankelasku.com/welcome' />
        <meta name='description' content='placeholder' />
      </Helmet>
      
      <div className='welcome-page'>
        <div className='card-item mb-2 welcome-label'>
          <p className='mb-0'>Welcome, {user?.FullName}</p>
        </div>
        <form className='card-content' onSubmit={formik.handleSubmit}>
          <h2>School List</h2>

          <div className='form-input'>
            <FormSelect
              label='School'
              data={schools.filter(item => item.status)}
              placeholder='Choose your school'
              onChange={(e) => {
                formik.setFieldValue(
                  "schoolId",
                  parseInt(e.target[e.target.selectedIndex].id)
                );
                formik.setFieldValue("schoolName", e.target.value);
              }}
              name='schoolName'
              value={formik.values.schoolId}
              onBlur={formik.handleBlur}
            />
            {
              formik.errors.schoolId && formik.touched.schoolId &&
              <div className='text-danger input-invalid'>
                {formik.errors.schoolId}
              </div>
            }
          </div>

          <div className='buttons'>
            <button
              className='btn-continue'
              // disabled={!formik.values.schoolId}
              type="submit"
            >
              Continue
            </button>
            <button className='btn-logout' onClick={() => dispatch(logout())}>
              Logout
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Welcome;
